.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-items: center;
  gap: 10px;
  margin: 0 10px;
}

.assessmentResults,
.kyb {
  padding: 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  width: 100%;
  height: 588px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
}

.badges {
  display: grid;
  grid-template-columns: repeat(4, 110px);
  row-gap: 10px;
}

.kyb > h1 {
  font-weight: 500;
  font-size: 24px;
}

.kyb h2 {
  font-weight: 600;
  font-size: 18px;
}

.kyb > p {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  margin: 8px 0;
}

.kyb li {
  font-size: 16px;
  font-weight: 400;
  line-height: 18.8px;
}

.kyb ul > div {
  margin: 10px 0;
}

.brain {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.content {
  pointer-events: none;
  width: 100%;
}

.content > p {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  margin: 8px 0;
}

.buttons {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.speakBtn{
  position: absolute;
  top: 48%;
    left: 35%;
}
.selfCareButton{
  width:100%
}


@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .assessmentResults,
  .kyb {
    height: auto;
  }
  
}

@media only screen and (max-width: 768px) {
  .container {
    display: block;
    margin: 10px;
  }

  .assessmentResults,
  .kyb {
    width: auto;
    height: auto;
    margin-top: 10px;
  }

  .badges {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .selfCareButton {
    display: flex;
    justify-content: center;
    width:100%
  }
  .speakBtn{
    position: absolute;
    top: 88%;
    left: 25%;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .content {
    width: 315px;
    margin-top: 10px;
  }

  .buttons {
    flex-direction: column;
    align-items: center;
    height: 105px;
  }
  .speakBtn{
    position: absolute;
    top: 62%;
    left: 25%;
  }

  .brain {
    margin: 10px 0;
    flex-direction: column-reverse;
  }

  .kyb ul {
    align-self: flex-start;
  }
}
