.sessionCard,
.sessionCard div {
  display: flex;
}

.sessionCard {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  height: 120px;
  color: #2a2a2a;
  align-items: center;
  width: 100%;
  justify-content: space-around;
}

.sessionCard h1 {
  font-size: 18px;
  font-weight: 600;
  align-self: flex-start;
}

.sessionCard > div {
  width: 94%;
  flex-direction: column;
}

.sessionInfo {
  width: inherit;
  display: flex;
  justify-content: space-between;
}

.sessionInfo div {
  flex-direction: column;
  margin: 10px 20px 0 0;
  text-align: left;
}

.sessionInfo div p:nth-child(1) {
  font-weight: 300;
}

.sessionInfo div p:nth-child(2) {
  font-size: 18px;
}
