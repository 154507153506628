.health {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.health > div > div {
  display: grid;
  grid-template-columns: repeat(2, 430px);
  row-gap: 30px;
  column-gap: 40px;
  margin-bottom: 15px;
}

.btn {
  align-self: flex-end;
}

@media only screen and (max-width: 768px) {
  .health > div > div {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 30px;
    column-gap: 40px;
    margin-bottom: 15px;
  }
}
