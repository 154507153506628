.addAnnouncement {}

.addButtuon {
    background-color: #00b091;
    border-radius: 50px;
    margin: 0;
    padding: 10px 20px;
    border: none;
    color: white;
    font-size: 1rem;
    cursor: pointer;
}