.tableData {
  font-size: 16px;
}

.avatar {
  display: flex;
  align-items: center;
  height: 0;
  padding: 18px 0;
}
