.customSelect {
    width: 100%;
  }
  
  .subText {
    margin: 0 0 0 26px;
  }
  
  .customSelect .selectToggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: left;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 8px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 6px;
  }
  
  .customSelect .selectOptions {
    background-color: #fff;
    border: 1px solid #ccc;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .customSelect .selectOptions li {
    padding: 8px;
  }
  
  .customSelect .selectOptions li:hover {
    background-color: #0a95ff;
    color: #fff;
  }
  
  .customSelect .selectOptions label {
    display: flex;
    cursor: pointer;
    align-items: flex-start;
  }
  
  .customSelect .selectOptions label input[type='radio'] {
    cursor: pointer;
  }
  
  .dropDownData {
    display: flex;
    flex-direction: column;
    margin-left: 7px;
  }

  .monthText {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #2A2A2A;
    margin-bottom: 5px;
  }

  .sessionName {
    font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 14px;
color: #666666;
  }

  .selectedValue {
    display: flex;
    align-items: flex-start;

  }

  .formControl {
    display: flex;

  }

  .formControl input[type="radio"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: var(--form-background);
    /* Not removed via appearance */
    margin: 3px 0 0 0;

    font: inherit;
    color: #7a51ae;
    width: 13px;
    height: 13px;
    border: 0.15em solid #bebebe;
    border-radius: 50%;
    transform: translateY(-0.075em);
  
    display: grid;
    place-content: center;
  }
  
  .formControl input[type="radio"]::before {
    content: "";
    width:6px;
    height: 6px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    /* Windows High Contrast Mode */
    background-color: #7a51ae;
  }
  
  .formControl input[type="radio"]:checked {
    border: 0.15em solid #7a51ae;
  }
  
  .formControl input[type="radio"]:checked::before {
    transform: scale(1);
  }