.sidebar {
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
  border-radius: 0px 12px 12px 0px;
  width: 240px;
  height: 93.5vh;
}
.tablecontainer{
  position: absolute;
  top:200px;
  left:260px;
  width:fit-content
}
.sidebar_container{
  display: flex;
}
.section_container{
padding: 20px;
width: 80%;
display: flex;
flex-wrap: wrap;
}
.assesmentmain{
  width:100%;
  margin:0px auto;
}