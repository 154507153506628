.header {
  position: sticky;
  z-index: 3;
}

.responsiveHeader {
  display: none;
  position: relative;
}

.responsiveHeader>img {
  height: 300px;
}

.responsiveHeader>div {
  width: 100%;
  padding: 0 14px;
}

.form {
  position: relative;
  bottom: 60px;
}

.hamburger {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
}

/* .hamburger > svg {
    margin: auto;
} */

.responsiveHeaderTitle {
  position: absolute;
  font-weight: 500;
  line-height: 36px;
  top: 155px;
  text-align: center;
  margin: 0 10px;
  color: #fff;
  font-size: 24px;
}

.responsiveHeader>img {
  width: 100%;
  object-fit: cover;
}

.content {
  background-image: url("../Components/Images/thumbnail_Enterprise.jpg");
  background-repeat: round;
  /* height: 740px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 28px;
}

.content>h2 {
  bottom: 20px;
  padding: 10px 15px;
  font-size: 42px;
  color: #000000;
  font-weight: 600;
  line-height: 70px;
  align-self: flex-end;
  position: relative;
  opacity: 0.7;
  background: #fff;
  width: 670px;
}

.logo {
  margin: 14px 0 0 30px;
  display: flex;
  gap: 30px;
  align-items: center;
  position: absolute;
  top: 0;
}

.links {
  color: #fff;
}

.links>ul {
  display: flex;
}

.links>ul>li {
  list-style-type: none;
  margin: 0 16px;
  font-weight: 500;
}

.links>ul>li>a {
  color: #000;
  text-decoration: none;
}

.links>ul>li>a:hover {
  color: #3592ff;
}

.call {
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid #ffffff;
  border-radius: 8px;
  width: 220px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 14px 30px 0 0;
  cursor: pointer;
}

.header .call>a {
  color: #fff;
  text-decoration: none;
}

.call:hover {
  border-color: #3592ff;
}

.call:hover .call_link {
  color: #3592ff;
}

.contact {
  width: 520px;
  height: 564px;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  padding: 20px;
}

.contact>div>h2 {
  font-size: 36px;
  font-weight: 500;
  margin: 8px 0;
  text-align: center;
}

.contact>div {
  margin: auto;
  width: 96%;
}

@media only screen and (max-width: 600px) {
  .header {
    display: none;
  }

  .links>ul {
    display: none;
  }

  .content {
    background-repeat: no-repeat;
    flex-direction: column;
    display: none;
  }

  .content h1 {
    font-size: 25px;
    font-weight: 500;
    line-height: 40px;
    width: auto;
    margin-bottom: 10px;
    text-align: center;
  }

  .contact {
    width: 100%;
    height: auto;
    padding: 16px 20px;
    align-self: center;
  }

  .contact>div>h2 {
    font-size: 24px;
    text-align: center;
  }

  .responsiveHeader {
    display: block;
  }
}

@media only screen and (max-width: 280px) {
  .responsiveHeaderTitle {
    line-height: 32px;
    top: 140px;
  }

  .contact>div>h2 {
    font-size: 22px;
  }
}