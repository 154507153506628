.cardContainer {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
}

@media only screen and (max-width: 768px) {
  .cardContainer {
    grid-template-columns: 1fr 1fr;
  }
}


.timePicker{
  border-radius: 5px;
  padding: 10px;
  width: 150px;
  font-size: 15px;
  border: 2px solid gainsboro;
}


.outerTimePicker{
  margin-bottom: 8px;
  color: #000;
  font-weight: 500;
  height: 42px;
}