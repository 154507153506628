.addSmsBtn{
    margin-top: 20px;
}

/* Mobile Responsive CSS */

@media only screen and (max-width: 600px) {
   
    
  
  }
  