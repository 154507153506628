.articleCard {
  height: 430px;
  border-radius: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
}

.articleCard>img {
  width: 100%;
  max-height: 307px;
  border-radius: 20px 20px 0 0;
  object-fit: cover;
  object-position: top;
}

.articleCard>div>p {
  font-weight: 300;
  font-size: 14px;
  margin-top: 12px;
  line-height: 20px;
}

.articleCard h2 {
  font-weight: 500;
  font-size: 18px;
  margin: 12px 0;
}

.articleCard>div {
  margin: 0 12px;
}

.author {
  display: flex;
  height: 40px;
  align-items: center;
}

.author>p {
  margin-left: 5px;
}

@media screen and (min-width: 577px) and (max-width: 769px) {
  .articleCard {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}

@media (max-width: 576px) {
  .articleCard {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}