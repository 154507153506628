.link {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: none;
  height: 42px;
}

.patientLink {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: none;
  height: 44px;
}

.enterpriseLink {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: none;
  height: 50px;
}

.link:hover {
  background-color: rgb(0, 176, 145, 0.2);
  color: rgb(0, 176, 145);
  transition: color 100ms ease-out;
  visibility: visible;
  border-left: 5px solid rgb(0, 176, 145);
}

.patientLink:hover {
  background-color: #7a51ae33;
  color: #000;
  transition: color 100ms ease-out;
  visibility: visible;
  border-left: 5px solid #7a51ae;
}

/* .patientActive {
  background-color: #7a51ae33;
  color: #000;
  visibility: visible;
  border-left: 5px solid #7a51ae;
} */

/* .patientLink svg:hover {
  fill: #7a51ae;
  border-style: 3px;
} */

.enterpriseLink:hover {
  background-color: #3592ff30;
  color: #3592ff;
  transition: color 100ms ease-out;
  visibility: visible;
  border-left: 5px solid #3592ff;
}

.patientLink svg:hover {
  fill: #7a51ae;
}

.enterpriseLink svg:hover {
  fill: #3592ff;
}

.link svg:hover {
  fill: rgb(0, 176, 145);
}

.imgs {
  height: 25px;
  width: 25px;
}

.sidebarlink {
  text-decoration: none;
  color: black;
}