.card {
  background: transparent;
  border-radius: 0px 50px;
  width: 800px;
  /* height: 350px; */
  padding: 30px 0;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.card>p {
  font-size: 15px;
  font-weight: 300;
  line-height: 29px;
}

.profile {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-top: 30px;
  color: #242424;
}

.profile img {
  border: 1px solid #ccc;
  border-radius: 50px;
}

.profileName {
  margin-bottom: 10px;
  font-size: 24px !important;
  font-weight: 400;
}

.profile p {
  font-size: 16px;
}

@media only screen and (max-width: 600px) {
  .card {
    width: 260px;
    height: auto;
  }

  .profile {
    flex-direction: column;
    align-items: flex-start;
  }

  .profileName {
    font-size: 20px;
    margin-bottom: 5px;
  }

  .card>p {
    width: auto;
    font-size: 20px;
    line-height: 30px;
  }

  .profile img {
    width: 50px;
    height: 50px;
  }
}

@media only screen and (max-width: 280px) {
  .card {
    width: 170px;
  }

  .card>p {
    font-size: 16px;
    line-height: 25px;
  }

  .profileName {
    font-size: 16px;
  }

  .profile p {
    font-size: 13px;
  }

  .profile img {
    width: 40px;
    height: 40px;
  }
}