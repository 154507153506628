.cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .cards {
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .cards {
    grid-template-columns: repeat(1, 1fr);
  }
  }