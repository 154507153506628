.login_subcontainer {
    background: #ffffff;
    box-shadow: 0px 4px 12px 4px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    width: 30%;
    /* margin-top: 10px; */
    text-align: left;
    padding: 30px;
    /* margin-top:1%; */
    position: absolute;
    top: 50%;
    left: 50%;
  
    transform: translate(-50%, -50%);
  }
  .login_subcontainer .heading {
    font-size: 18px;
    margin-bottom: 22px;
    margin-top: 0px;
    font-weight: inherit;
  }
  .login_subcontainer .input input[type="text"] {
    background: #ffffff;
    border: 1px solid #dadada;
    border-radius: 8px;
    width: 100%;
    padding: 15px;
    /* margin-top: 10px; */
    outline-color: #00b091;
  }
  .login_subcontainer .input {
    width: 100%;
    margin-top: 10px;
  }
  
  .login_subcontainer .input > div > div {
    width: 100%;
  }
  
  .login_subcontainer input[type="submit"] {
    /* background: #00B091; */
    border-radius: 8px;
    width: 100%;
    border: none;
    color: #ffffff;
    padding: 12px;
    font-weight: 500;
    font-size: 18px;
  }
  .login_subcontainer .submit {
    width: 100%;
  }
  .login_container {
    height: 100vh;
    width: 100%;
    position: relative;
    background-position: bottom !important;
  }
  .forgot_text p {
    text-align: right;
    /* color: #00B091; */
    font-weight: 500;
    font-size: 14px;
  }
  .forgot_text {
    margin-bottom: 16px;
    margin-top: 8px;
  }
  .error_text {
    margin-bottom: 16px;
    margin-top: 8px;
  }
  
  .error {
    font-size: 13px;
    color: red;
  }
  
  .error_msg {
    text-align: left;
    padding: 5px 0;
    color: red;
  }
  
  .back_btn {
    bottom: 55px;
    left: 50px;
  }
  
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .login_subcontainer {
      width: 80%;
    }
    .back_btn {
      bottom: 20px;
      left: 10px;
    }
  }
  
  @media (max-width: 768px) {
    .login_container {
      margin-bottom: 20px;
    }
  }
  