.heading {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin: 0 0 10px 10px;
}

.subheading {
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  margin: 10px;
}

.container {
  display: flex;
  flex-wrap: wrap;
}

.otherAssContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 18px 10px;
  gap: 10px;
}

.linktext {
  font-size: 18px;
  font-weight: 500;
  margin: 20px 12px;
  text-align: center;
  line-height: 20px;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;
}

@media only screen and (max-width: 600px) {
  .container {
    justify-content: center;
    width: 100%;
    padding: 0;
    display: block;
  }

  .cards {
    display: block;
  }
}
