.eventCard {
  /* width: 100%; */
  padding: 0;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 20px;
  background-color: #fff;
  pointer-events: none;
  min-height: 270px;

}

.dashboardEventCard {
  /* width: 100%; */
  padding: 0;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 20px;
  background-color: #fff;
  pointer-events: none;
  min-height: 270px;
}

.eventCard div {
  /* width: 264px; */
  /* height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; */
}

/* .innerContainer {
  overflow-wrap: break-word;
   width: 264px; 
} */

.dashboard {
  min-height: 120px;
  height: 120px;
  overflow: auto;
}

.header {
  position: relative;
  display: flex;
  border-radius: 20px 20px 0 0;
  align-items: center;
  padding: 12px;
  background-color: #e69a8d1a;
}

.header h1 {
  font-size: 32px;
  font-weight: 300;
  color: #7a51ae;
}

.header h2 {
  font-size: 24px;
  font-weight: 500;
  color: #e69a8d;
}

.content {
  padding: 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  pointer-events: auto;
}

.content h2 {
  font-size: 15px;
  font-weight: 400;
  word-break: break-word;
  margin-bottom: 8px;
  /* height: 70px;
    max-height: 70px;
    overflow: auto; */
}

.content a {
  text-decoration: none;
}

.eventMode {
  margin: 15px 0;
  height: 15px;
  font-size: 14px;
}

.timing {
  display: flex;
  font-size: 14px;
  font-weight: 300;
  margin: 4px 0 4px 0;
}

.timing>p {
  margin: 0 5px;
}

.summary {
  font-size: 12px;
  font-weight: 300;
  margin: 6px 0;
  word-break: break-all;
  min-height: 80px;
  height: 80px;
  overflow-y: auto;
  pointer-events: auto;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .eventCard {
    /* width: 100%; */
    padding: 0;
  }

  .eventCard div {
    width: 100%;
  }

  .content {
    /* padding: 12px; */
  }
}