.contact {
  display: flex;
  background-color: #f5f5f5;
  justify-content: space-around;
  height: 700px;
  padding-top: 90px;
  background-image: url("https://www.samvednacare.com/uploads/home/banner_images/1657108584_039afeb3a2dfeda40326.webp");
  background-size: contain;
  background-repeat: round;
}

.contactlogo {
  text-align: center;
}

.contact h1 {
  font-size: 50px;
  font-weight: 500;
  display: flex;
  width: fit-content;
  /* line-height: 75.2px; */
  padding-left: 93px;
  padding-top: 5%;
  color: #000000;
}

.heading {
  margin: 80px 0px 13px -36px;
  text-align: left;
  padding-left: 8%;
  font-weight: 500;
  font-size: 35px;
}

.form {
  width: 100%;
  height: 528px;
  border-radius: 10px;
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  background-color: #fff;
}

.connectform {
  margin-right: 12%;
}

.inputs {
  width: 320px;
}

.inputs > div {
  margin: 10px 0;
}

.inputs > .buttons {
  /* float: right; */
}

.assessment {
  background: #f1f1f1;
  padding: 40px;
}

.services {
  padding: 40px;
}

/* assessment card */

.assessmentCard {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  width: 320px;
  height: 188px;
  background-color: #fff;
  display: flex;
}

.circular {
  background: #d9d9d9;
  width: 53px;
  height: 53px;
  border-radius: 50%;
  margin: 25px 25px 0 10px;
}

.assessmentCard .content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: inherit;
}

.assessmentCard .content p {
  margin: 8px 0;
  font-size: 20px;
  font-weight: 500;
}

.cards {
  padding-left: 4%;
}

/* service cards */

.servicesCard {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  width: 250px;
  height: 308px;
  background-color: #ffffff;
  /* opacity: 0.1; */
  /* padding: 10px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  /* width: 100%; */
}

.servicesCard .servicesCardHeader {
  background: #3592ff;
  opacity: 0.1;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  height: 136px;
  position: relative;
}

.servicesCard .img {
  height: 150px;
  border-radius: 20px 20px 0 0;
  /* background: #3592FF; */
  /* opacity: 0.1; */
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.servicesCard img {
  height: 80px;
}

.servicesCard .content {
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  /* margin-left: "10px"; */
  padding: 15px;
}

.servicesCard .content > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  /* margin-left: 8px;
  margin-top: 12px; */
}

.servicesCard .title {
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
}

.servicesCard .amount {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 8px 0;
}

/* FAQ */

.faq {
  margin-top: 10%;
  display: block;
  justify-content: center;
}

.faq h1 {
  font-weight: 500;
  font-size: 36px;
  line-height: 43px;
  width: inherit;
}

.faq p {
  margin: 5% 0;
  font-weight: 300;
  font-size: 20px;
  line-height: 27px;
  width: 570px;
  color: #898989;
}

.faq_grid {
  display: flex;
  width: inherit;
  justify-content: space-around;
  padding: 35px;
}

.trusted_partnership {
  border-left: 2px solid skyblue;
  padding-left: 35px;
  margin-top: 40px;
}

.trusted_partnership .partnershipText {
  font-size: 16px;
  margin-top: 16px;
}
.ourPartners {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  gap: 15px;
  padding: 5px;
}
@media only screen and (max-width: 600px) {
  .contact {
    flex-direction: column;
    height: max-content;
  }

  .connectform {
    margin-right: 0%;
  }

  .contact h1 {
    font-size: 22px;
    max-width: fit-content;
    text-align: center;
    line-height: 30px;
    padding: 0%;
  }

  .faq {
    align-items: center;
    width: max-content;
    display: flex;
  }

  .cards {
    flex-direction: column;
    display: block;
  }

  .assessmentCard {
    margin-bottom: 10px;
  }

  .form {
    height: auto;
  }

  .faq h1 {
    font-size: 22px;
  }

  .faq p {
    font-size: 16px;
    width: auto;
  }

  .faq_grid {
    flex-direction: column;
  }

  .servicesCard {
    position: relative;
    flex-basis: 250px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .servicesCard {
    position: relative;
    flex-basis: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    margin-top: 50px;
  }
 }
