.feedback {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.feedback .heading {
  font-weight: 500;
  font-size: 24px;
  padding: 16px 0;
  line-height: 24px;
}

.feedback .sub_heading {
  font-weight: 600;
  font-size: 16px;
}

.feedback > div {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  padding: 22px;
  min-height: 80vh;
}

.feedback .give_feedback,
.feedback .my_feedbacks {
  background-color: #fff;
}

.feedback .give_feedback {
  width: 53%;
}

.feedback .my_feedbacks {
  width: 40%;
}

.feedback textarea {
  height: 148px;
  display: block;
  width: 100%;
  margin: 10px 0;
  resize: none;
  padding: 10px;
  outline: none;
  border: 1px solid #dadada;
  border-radius: 8px;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 20px;
}

.cards {
  overflow-y: auto;
  height: 366px;
}

.feedback_card {
  border: 1px solid #dadada;
  border-radius: 8px;
  width: auto;
  min-height: 192px;
  padding: 10px;
  margin: 10px;
}

.feedback_card .more {
  color: #7a51ae;
  cursor: pointer;
}

.feedback_card div {
  display: flex;
}

.feedback_card > div {
  flex-direction: column;
}

.feedback_card > div > div {
  justify-content: space-between;
}

.feedback_card .text {
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  margin: 10px 0;
  word-wrap: break-word;
}

.feedback_card .icons svg {
  margin: 0 6px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .feedback {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    width: 100%;
  }

  .feedback > div {
    min-height: auto;
  }

  .feedback .give_feedback,
  .feedback .my_feedbacks {
    width: 92%;
    margin: 10px 0;
    align-self: center;
  }

  .cards {
    overflow-y: unset;
    height: auto;
  }
}
