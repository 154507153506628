.bg::before {
  /* ../../assets/icons/icon/grass.svg */
  background-image: url("../../icons/icon/grass.svg");
  position: fixed;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 200px;
  z-index: -9;
}

.bg {
  visibility: none;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  width: 184px;
  height: 56px;
  margin: 20px 32px;
}
.container {
  display: flex;
  justify-content: center;
  height: 90vh;
}
.bgSubContainer {
  width: 85%;
}
.titleContainer h1 {
  font-size: 24px;
  margin-top: 25px;
  font-weight: 700;
  line-height: inherit;
}

.subHeading {
  margin-top: 20px;
  font-size: 17px;
  font-weight: normal;
  line-height: normal;
  color: #555555;
}

.subHeadingContainer p {
  margin-top: 30px;
  font-size: 17px;
  font-weight: normal;
  line-height: normal;
  color: #555555;
}

.image {
  display: flex;
  justify-content: center;
  padding: 10px;
}
.bulletPoints {
  margin-top: 30px;
}

.bulletPoints li {
  list-style: none;
}

.cardText {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.cardText p {
  font-size: 17px;
  font-weight: normal;
  line-height: normal;
  color: #555555;
}

.images {
  width: 30px;
  margin-right: 10px;
}

.didntfind {
  margin-top: 20px;
}

.didntfind p {
  margin-top: 30px;
  font-size: 17px;
  font-weight: normal;
  color: #555555;
  line-height: normal;
}

.bottomContainer {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background-color: #f8f8f8;
}

.btnContainer {
  width: auto;
}

.btnContainer p {
  width: auto;
  text-align: center;
}

.btnContainer div {
  display: flex;
  align-items: center;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  /* Styles */
  .cardContainer {
    flex-direction: column;
    align-items: center;
  }

  .loginText {
    display: none;
  }

  .btnContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .bottomContainer {
    padding: 10px;
    position: sticky;
    background-color: #f8f8f8;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Styles */
}

/* iPads (landscape) ----------- */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* Styles */
}
