.search_bar {
  background: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  color: #000;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 3px;
  background-position: 10px center;
}
.search_bar_height {
  padding: 5px;
  height: 40px;
}
.search_bar input {
  border: none;
  width: 100%;
  height: 100%;
  outline: none;
  background: #fff;
  border-radius: 8px;
  color: #000;
  border-radius: 8px;
  border: none;
  /* background: #FAFAFA; */
  display: flex;
  padding: 3px;
  font-weight: 300;
  font-size: 14px;
}

.search_bar input::placeholder {
  font-weight: 300;
  font-size: 14px;
}

.search_bar svg {
  margin: 5px;
}
