.session {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
    margin: 20px 0;
}

.addReport {
    text-align: right;
}


@media (max-width: 768px) {
    .session {
        display: inherit;
        margin: 20px 0;
    }
}