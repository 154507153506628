
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}
.timeSlotContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 2 calc(16% - 8px);
  
}




/* .selectedItem {

} */

.timeSlotContainerView {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;

  
}

.timeDisable {
  opacity: 0.4;
  cursor: not-allowed;
}

.checkBoxes .timeDisable input + span {
  cursor: not-allowed;
}

.weekDays {
font-style: normal;
margin-bottom: 8px;
font-weight: 600;
font-size: 12px;
line-height: 14px;
display: flex;
align-items: center;
color: #2A2A2A;
}
  .checkBox{
    overflow: hidden;
    width: 40px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #DADADA;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .checkBox label {

}

.checkBoxes{
    display: flex;
    align-items: center;
    gap: 20px;
    flex-direction: row;
}

  
  .checkBox label span {
    text-align: center;
    padding: 3px 0;
    display: block;
  }
  
  .checkBox label input {
    position: absolute;
    display: none;
    color: #fff !important;
  }
  /* selects all of the text within the input element and changes the color of the text */
  .checkBox label input + span{
    color: #2A2A2A;
    font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 16px;
text-align: center;
}
  
  
  /* This will declare how a selected input will look giving generic properties */
  .checkBox input:checked + span {
      color: #ffffff;
      text-shadow: 0 0  6px rgba(0, 0, 0, 0.8);
  }
  
  
  /*
  This following statements selects each category individually that contains an input element that is a checkbox and is checked (or selected) and chabges the background color of the span element.
  */

  .checkedCheckBox input + span {
    width: 40px;
    height: 40px;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  .checkedCheckBox input + span {
    width: 40px;
    height: 40px;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  .checkedCheckBox input:checked + span{
    background-color: #00B091;
    width: 40px;
    height: 40px;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }





      .timeSlotCheckBox{
        overflow: hidden;
        width: 100px;
        height: 48px;
        background: #FFFFFF;
        border: 1px solid #DADADA;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .timeSlotCheckBoxView {
        overflow: hidden;
        width: 90px;
        height: 36px;
        background: #FFFFFF;
        border: 1px solid #DADADA;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
     
      }
      
      .timeSlotCheckBox label {
    
    }
    
    .timeSlotCheckBoxes{
      
        display: flex;
        align-items: center;
        gap: 20px;
        flex-direction: row;
        flex-wrap: wrap;
    }
    
      
      .timeSlotCheckBox label span {
        text-align: center;
        padding: 3px 0;
        display: block;
      }
      
      .timeSlotCheckBox label input {
        position: absolute;
        display: none;
        color: #fff !important;
      }
      /* selects all of the text within the input element and changes the color of the text */
      .timeSlotCheckBox label input + span{
        font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    }
      
      
      /* This will declare how a selected input will look giving generic properties */
      /* .timeSlotCheckBox input:checked + span {
          color: #ffffff;
          text-shadow: 0 0  6px rgba(0, 0, 0, 0.8);
      }
       */
      
      /*
      This following statements selects each category individually that contains an input element that is a checkbox and is checked (or selected) and chabges the background color of the span element.
      */
      .timeSelectCheckedCheckBox input + span {
        width: 100px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-style: normal;
font-weight: 600;
font-size: 14px;
      }
      
      
      .selectedItem{
        width: 100px;
        height: 48px;
        background-color: #2A2A2A;
        cursor: pointer;
        font-style: normal;
font-weight: 600;
font-size: 14px;
color: #ffffff;

      }

      .bookedTime {
        border: 4px solid #F19204;

      }

      .selectCheckedCheckBoxView input + span {
        width: 90px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-style: normal;
font-weight: 600;
font-size: 12px;
      }
      
      
      .selectCheckedCheckBoxView input:checked + span{
        width: 90px;
        height: 36px;
        background-color: #2A2A2A;
        cursor: pointer;
        font-style: normal;
font-weight: 600;
font-size: 12px;
color: #ffffff;
       
      }

      .bookedTimeSlot {
        background: #2A2A2A;
        color: #ffffff;
      }

      .bookedTimeSlot span {
        color: #fff;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
   
      }

      .selectCheckedCheckBoxView span {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
      }

      
@media (max-width: 768px) { 
  .timeSlotCheckBox{
    width: 82px;
  }
  .timeSlotCheckBox label{ 
    font-size: 10px;
  }
  .timeSlotCheckBox label input + span{ 
    font-size: 10px;
  } 
}