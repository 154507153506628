.general_obv {
    display: flex;
    gap: 65px;
    margin: 20px;
}
.addEvent {
    display: flex;
    gap: 20px;
}
.avatar {

}
.general_obv input {
width: 320px;
    margin-top: 5px;
}
.event_detail{
    display: flex;
    flex-wrap: wrap;
    margin: 10px 10px;
}
.detail_card p{
        margin: 10px;
}
.detail_card_description{
    font-weight: 600;
        font-size: 16px;
}
.detail_card_title{
    font-weight: 400;
        font-size: 14px;
}

.AddEventButtonsWrapper{
    display: flex;
    margin: 1em 0em;
    width: 300px;
    /* justify-content: space-around; */
    gap: 20px
}


