.mainChart {
  display: flex;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  padding: 25px 20px 25px 0px;
  width: 465px;
}

.customLabel img {
  width: 28px;
  height: 28px;
}
.firstRow {
  margin-top: 10px;
}
.firstRow,
.secondRow,
.thirdRow {
  display: flex;
  gap: 50px;
  margin-bottom: 30px;
  justify-content: space-between;
}

.firstRow div,
.secondRow div,
.thirdRow div {
  display: flex;
  align-items: center;
  gap: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #2a2a2a;
}

/* bar chart css here */

.mainChartWrapper {
  display: flex;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  flex-direction: column;
  padding: 25px 20px 25px 10px;
  width: 465px;
}

.bottomLabel {
  display: flex;
  justify-content: space-around;
  padding: 0 10px;
}

.bottomlabelText {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
}

.bottomlabelText span {
  width: 12px;
  display: block;
  height: 12px;
  border-radius: 100px;
}

.bottomlabelText .LevelOne {
  background-color: #ee4925;
}
.bottomlabelText .LevelTwo {
  background-color: #aac514;
}
.bottomlabelText .LevelThree {
  background-color: #23c363;
}
.bottomlabelText .LevelFour {
  background-color: #f2841d;
}

.bottomText .levelSec {
  margin-bottom: 4px;
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #2a2a2a;
}

.bottomText .hourSec {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 500;

  font-size: 14px;
  line-height: 16px;
  color: #2a2a2a;
}

@media (max-width: 480px) {
  .mainChartWrapper {
    width: 100%;
  }
  .mainChart {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .customLabel {
    padding: 10px;
  }
}

@media (max-width: 420px) {
  .mainChartWrapper {
    width: 90%;
  }
  .mainChart {
    width: 90%;
  }
}

@media (max-width: 280px) {
  .mainChartWrapper {
    width: 250px;
  }
  .mainChart {
    width: 250px;
    padding: 8px;
  }
}
