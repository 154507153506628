.typography {

}
@media (max-width: 769px) {  

    .typography {
        padding-left: 0px !important;
    }
    .search {
        padding-left: 0px !important;
        width: 100%;
        padding-top: 0px !important;
    }
    .filter {
        padding-left: 0px !important;
    }

    .exports {
        padding-left: 0px !important;
    }
    .addButton {
        padding-left: 0px !important;
    }
  }