.addService {
  border: 1px solid #eeeff4;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  border-radius: 8px;
  padding: 16px;
}

.addService label {
  font-size: 14px;
  margin-bottom: 8px;
}

.buttons_flex {
  display: flex !important;
}

.inputs {
  display: grid;
  grid-template-columns: repeat(2, 0.4fr);
}

.inputs input,
.inputs select {
  width: 320px;
  height: 48px;
  border-radius: 8px;
  outline: none;
  border: 1px solid #dadada;
  padding: 12px;
  font-size: 16px;
}

.inputs>div {
  /* display: flex; */
  /* flex-direction: column; */
  margin-bottom: 20px;
}

::placeholder {
  color: #b8b8b8;
}

::-webkit-input-placeholder {
  color: #b8b8b8;
}

.checkboxes>div,
.checkBoxWrapper,
.description {
  display: flex;
}

.checkboxes>div>div {
  margin-right: 8px;
}

.checkBoxWrapper {
  width: 90%;
  justify-content: space-between;
}

.description {
  margin-top: 18px;
  flex-direction: column;
}

.description textarea {
  border-radius: 8px;
  height: 96px;
  resize: none;
  outline: none;
  padding: 12px;
  font-size: 16px;
}

.detailsCard {
  height: auto;
  width: 90%;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  border-radius: 8px;
  border: 1px solid #eeeff4;
  padding: 16px;
  background: #fff;
  margin-bottom: 20px;
}

.detailsCard_container {
  text-align: right;
  width: 70%;
  padding: 10px;
}

.status_btn label {
  margin-left: 1px;
}