.card {
  width: 440px;
  height: 120px;
  border: 1px solid #ededed;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.cardTitle {
  font-size: 48px !important;
  font-weight: 700 !important;
  color: #3592ff;
}

.cardDesc {
  width: 250px;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px !important;
}

@media only screen and (max-width: 600px) {
  .card {
    width: 46%;
    height: 100px;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
  }

  .cardTitle {
    font-size: 32px !important;
    margin: 0 !important;
  }

  .cardDesc {
    line-height: inherit !important;
    margin-left: 10px !important;
    font-size: 13px !important;
    width: auto !important;
    margin: 0 !important;
    font-weight: 600 !important;
  }
}

@media only screen and (max-width: 280px) {
  .cardDesc {
    font-size: 12px !important;
    margin: 0 !important;
  }

  .cardTitle {
    font-size: 32px !important;
  }
}
