.common_container {
  height: 100vh;
  width: 100%;
  position: relative;
  background-position: bottom !important;
}

.commom_subcontainer {
  background: #ffffff;
  box-shadow: 0px 4px 12px 4px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  width: 40%;
  text-align: left;
  padding: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.common_text {
  font-size: 18px;
  line-height: 30px;
}

@media only screen and (max-width: 768px) {
  .commom_subcontainer {
    width: 80%;
  }
}
