.general_obv{
    display: flex;
    gap: 65px;
    margin: 20px;
}

.general_obv div{
    width:320px;
    margin-top: 5px
}

.general_obv .accordion_opt{
    padding: 0 !important;
}
.heading h1{
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 18px;
    margin-top: 10px;
}
.ill_history{
    margin-bottom:50px
}

.score_container_title{
background: #FFFFFF;
border: 1px solid #EAEAEA;
border-radius: 8px 8px 0px 0px;
width: 1104px;
    height: 52px
}
.score_container_desc{
background: #F8F8F8;
    border: 1px solid #EAEAEA;
    border-radius: 0px 0px 8px 8px
}
.score_container_inputs{
        display: flex;
            gap: 60px;
            margin: 30px;
}
.score_container_title p{
    margin:15px;
        margin-left: 30px
}
.score_container_desc p{
    margin-left: 30px;
        margin-top: 12px;
}
.score_container{
            margin-top: 15px;
            margin-bottom: 15px;
}
.summary_btn{
    margin-top:20px;
    margin-bottom: 20px
}