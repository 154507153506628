.heading_container {
  display: flex;
  justify-content: space-between;
}

.rowContainer {
  display: flex;
  margin: 5px 0;
}

.colContainer {
  width: 50%;
}

.colContainer p {
  margin: 10px 0;
}

.valueData {
  font-weight: 600;
}
.servicemodal {
  width: 850px;
  height: 550px;
}

.servicemodal::-webkit-scrollbar {
  width: 10px;
}

.servicemodal::-webkit-scrollbar-track {
  background: transparent;
}

.servicemodal::-webkit-scrollbar-thumb {
  background: #0f0f0f40;
  border-radius: 100px;
  /* border: 1px solid transparent; */
  /* background-clip: content-box; */
}

@media only screen and (max-width: 600px) {
  .servicemodal {
    width: 100%;
    height: 600px;
  }
  .rowContainer {
    flex-direction: column;
  }
  .colContainer {
    width: 100%;
  }
}
