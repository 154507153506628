.chartMain {}

.topHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.topHeader h2 {

  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #2A2A2A;

}

.bottomText {
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  margin: 8px 0;
  padding: 4px 8px;
  display: inline-flex;
}

.bottomText>p {
  font-size: 13px;
}

.chartWrapper {
  display: flex;
  gap: 36px;
  flex-direction: row;
  justify-content: flex-start;
}

.cardChart {
  margin-bottom: 20px;
}

.cardChart img {
  width: 100%;
}

.innerResultOverview {
  display: flex;

}

.papercontainer {
  width: fit-content;
}

.chartHead {
  font-size: 24px;
  padding: 15px 0;
  font-weight: 600;
}

/* top box css start */

.topBoxes {
  background: #FFFFFF;
  border: 1px solid #EEEFF4;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  border-radius: 8px;
  padding: 15px;
}

.overVieBoxes {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.overViewBox {}

.overViewSec {
  width: 50%;
}

.overViewBox h3 {

  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #2A2A2A;
  margin-bottom: 15px;
}

.ovBox {
  background: #FFFFFF;
  display: flex;
  gap: 12px;
  border: 1px solid #EEEFF4;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  border-radius: 8px;
  width: 260px;
  height: 100px;
  align-items: center;
  padding: 20px;
}

.boxName h4 {

  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  color: #2A2A2A;
}

.bottomHeader {
  margin-bottom: 16px;
  margin-top: 32px;
}

.boxName h2 {

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #2A2A2A;
}

.mainBox {
  display: flex;
  gap: 15px;
}

.selectDrop {
  margin-bottom: 20px;
}

.bottomChart {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bottomChart a {}

@media only screen and (max-width: 768px) {
  .chartWrapper {
    flex-direction: column;
  }

  .chartHead {
    font-size: 18px;
  }
}