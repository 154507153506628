.feedbackModal {
  width: 400px;
  height: 350px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .feedbackModal {
    width: 95%;
  }
}
