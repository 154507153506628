.summaryCard {
  margin-bottom: 26px;
  width: 100%;
    overflow: auto;
}

.data {
  display: grid;
  row-gap: 10px;
  column-gap: 30px;
  margin-bottom: 18px;
  grid-template-columns: repeat(2, 1fr);
}

.dataService {
  display: grid;
  row-gap: 10px;
  column-gap: 30px;
  margin-bottom: 18px;
  grid-template-columns: repeat(3, 1fr);
}

.dataRow {
}

.name {
  font-size: 14px;
  margin-top: 14px;
}

.value,
.description {
  font-size: 16px;
  font-weight: 600;
  margin: 10px 0;
}

.value button {
  height: 12px;
  padding: 0;
  color: #7A51AE;
  text-decoration: underline;
}

.value button:hover
 {
  background-color: #fff;
  border-color: #fff;
  box-shadow: none;
}

.description {
  line-height: 20px;
}

.heading {
  font-size: 20px;
  font-weight: 700;
  margin: 8px 0;
}
