.slick-prev:before, .slick-next:before {
    display: none;
}

.slick-prev {
    left: -25px;
    z-index: 1;
    top: 114px;
}

.slick-next {
    right: 20px;
    top: 114px;
}


.slick-active > div {
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 600px) {
    .slick-prev {
        left: 12px;
        top: 200px;
    }

    .slick-next {
        right: 32px;
        top: 200px;
    }
}