.darkBG {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.timeSlot option {
  color: green;

}

.contactUs {
  color: #000;
  font-size: 15px;
  padding: 0 36px;
  margin: 10px 0;
  font-weight: 600
}


.timeSlot {
  appearance: none;
  -webkit-appearance: none;
}


.timeSlot option:disabled {
  color: red;

}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2 !important;
  background: #00000080;
  width: 100vw;
  height: 100vh;
}

.modal {
  width: 700px;
  overflow: auto;
  background: white;
  color: white;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  margin: auto;
  margin-top: 6%;
  padding-bottom: 15px;
}

.doctorDescriptiontag {
  font-size: 16px;
  color: #000;
  line-height: 24px;
  font-weight: 500;
  min-height: 60px;
  max-height: 100px;
  overflow: auto;
  margin-bottom: 7px;
}

.modalHeader {
  background: white;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  position: relative;
}

.heading {
  margin: 0;
  padding: 20px 36px 5px 36px;
  background: #ffffff;
  font-weight: 600;
  color: #000000;
  font-size: 20px;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalContent {
  padding: 0 36px 0px 36px;
  color: #2c3e50;
  text-align: left;
  margin-bottom: 0px;
  font-size: 15px;
}

/* .modalContent p {
  font-size: 16px;
  color: #000;
  line-height: 24px;
  font-weight: 500;
  display: flex;
} */
.modalContent p span {
  flex: 1;
  font-size: 15px;
  padding: 10px;
  height: 42px;
  margin-bottom: 8px;
  border-radius: 5px;
}

.modalContent p span:first-child {
  /* border: none;
  padding-left: 0px;
  padding-right: 20px;
  text-transform: uppercase;
  font-size: 14px; */
}

.modalActions {
  /* position: absolute; */
  bottom: 2px;
  margin-bottom: 5px;
  width: 100%;
}

.actionsContainer {
  text-align: right;
  margin-right: 30px;
}

.titleHead {
  font-size: 14px;
  margin-top: 14px;
  color: #000;
  margin-bottom: 6px;
}

.modelDescription {
  font-size: 16px;
  color: #000;
  line-height: 24px;
  font-weight: 500;


}

.modelheader {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.modelheaderInner {}

/* .closeBtn {
    cursor: pointer;
    font-weight: 500;
    padding: 18px 24px;
    border-radius: 8px;
    border: none;
    font-size: 18px;
    color: #2c3e50;
    background: white;
    transition: all 0.25s ease;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
    position: relative;
    left: 515px;
    bottom: 55px;
    align-self: flex-end;
    margin-top: -7px;
    margin-right: -7px; 
} */

.closeBtn {
  cursor: pointer;
  font-weight: 500;
  padding: 5px 8px;
  border-radius: 8px;
  border: none;
  font-size: 30px;
  color: #2c3e50;
  background: white;
  transition: all 0.25s ease;
  box-shadow: 0 5px 20px 0 rgb(0 0 0 / 6%);
  position: relative;
  bottom: 0px;
  left: 0px;
}

.closeBtn:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  /* transform: translate(5px, -5px); */
}

.deleteBtn {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: 0.02857em;
  padding: 11px 28px;
  border-radius: 5px;
  color: #fff;
  background: rgb(122, 81, 174);
  transition: all 0.25s ease;
  margin-bottom: 10px;
  display: inline-block;
  border: none;
}

.cancleBtn {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: 0.02857em;
  padding: 11px 18px;
  border-radius: 5px;
  color: #000;
  border: 1px solid #DADADA;
  background: #ffffff;
  transition: all 0.25s ease;
  margin-bottom: 10px;
  display: inline-block;
  border: none;
}

.deleteBtn:hover {
  /* box-shadow: 0 10px 20px -10px rgba(255, 62, 78, 0.6); */
  /* transform: translateY(-5px); */
  background: rgb(122, 81, 174);
}

.cancelBtn {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #2c3e50;
  background: #fcfcfc;
  transition: all 0.25s ease;
}

.cancelBtn:hover {
  box-shadow: none;
  transform: none;
  background: whitesmoke;
}

.app_time input {
  padding: 10px;
  width: 100%;
  font-size: 15px;
}

.app_time {
  margin-bottom: 8px;
  color: #000;
  font-weight: 500;
  height: auto;
}

.start_time input {
  padding: 10px;
  color: #000;
  font-weight: 500;
  border: 1px solid #e2e2e2;
  width: 200px;
}

.start_time {
  margin-bottom: 8px;
  font-size: 15px;
  color: #000;
  font-weight: 500;

}

.start_time .labeltext {
  width: 50%;
  max-width: 100%;
  flex: unset;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.02857em;
}

.start_time .inputfield {
  width: 100%;
  max-width: 100%;
  flex: unset;
  font-size: 15px;
  border-radius: 5px;
}

.forms {
  padding: 0 36px;
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.email {
  width: 50% !important;
}

.options {
  width: 50% !important;
}

@media (max-width: 768px) {
  .modal {
    width: 100%;
    max-width: 100%;
    margin-top: 10%;
  }

  .centered {
    width: 100%;
    max-width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  .modalContent {
    padding: 10px;
  }

  .forms {
    flex-direction: column;
    padding: 10px;
    padding-bottom: 0px;
    padding-top: 0px;
    flex-direction: column;
  }
}

@media only screen and (max-width: 768px) {
  .start_time {
    margin-top: 25px;
    width: 100%;
  }

  .app_time {
    width: 100%;
  }
}


@supports (-webkit-touch-callout: none) {
  .timeSlot option {
    color: green;
  }
}