.thoughts {
  width: 100%;
  height: 148px;
  outline: none;
  resize: none;
  font-size: 16px;
  padding-left: 8px;
  padding-top: 5px;
  border: 1px solid #dadada;
  border-radius: 8px;
}

.errorText {
  color: red;
  font-size: 16px;
}
