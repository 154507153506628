.header {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 18px 40px;
  }
  
  .responsiveHeader {
    display: none;
    position: relative;
  }
  
  .responsiveHeader > img {
    height: 300px;
  }
  

  
  .form {
    position: relative;
    bottom: 60px;
  }
  
  .hamburger {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
    flex-direction: row-reverse;
  }
  
  /* .hamburger > svg {
      margin: auto;
  } */
  
  .responsiveHeaderTitle {
    position: absolute;
    font-weight: 500;
    line-height: 36px;
    top: 155px;
    text-align: center;
    margin: 0 10px;
    color: #fff;
    font-size: 24px;
  }
  
  .responsiveHeader > img {
    width: 100%;
    object-fit: cover;
  }
  
  .bannerMain {
    background-repeat: round;
    height: 620px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    gap: 50px
  }
  .content {
    flex: 1 1;
    margin-top: 50px;
  
  }

  .bannerImg {
    width: 100%;
    flex: 2.5 1;
    display: block;
  }
  .bannerImg img {
    width: 100%;
  }
  
  .contentBox > h1 {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 42px;
    color: #FFFFFF;
    margin-bottom: 12px;
  }
  .contentBox > h2 {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 33px; 
    color: #FFFFFF;
    margin-bottom: 12px;
  }

  .contentBox > h3 {
    font-family: 'Public Sans';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 32px;
color: #FFFFFF;
margin-bottom: 26px;
  }

  .textBoxes {
    display: flex;
    justify-content: space-between;
    gap : 20px;
 
  }

  .cardBox {
    background: #FFFFFF;
    border: 1px solid #EDEDED;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    width: 200px;
    text-align: center;
    padding: 16px;
  }

  .cardBox h3 {
    font-family: 'Public Sans';
font-style: normal;
font-weight: 600;
font-size: 13px;
line-height: 20px;
text-align: center;
color: #242424;
margin-top: 12px;
  }
  .bannerWrap {
    background: #7A51AE;
    display: block;
  }
.bannerWrapResponsive {
  background: #7A51AE;
  display: none;
}
  .bottomLine {
    font-family: 'Public Sans';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 32px;
color: #FFFFFF;
padding: 0 0px 20px 40px;
  }

  .btnGroup {
    margin-top: 36px;
  }

  .btnGrp {
    gap: 20px;
    display: flex;
  }
  
  .logo {
    display: flex;
    gap: 30px;
    align-items: center;
   
  }
  
  .links {
    color: #fff;
  }
  
  .links > ul {
    display: flex;
  }
  
  .links > ul > li {
    list-style-type: none;
    margin: 0 16px;
    font-weight: 500;
  }
  
  .links > ul > li > a {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;
    text-decoration: none;
  }

  .links > ul > li > a:hover {
    color: #FFFFFF;
  }
  
  .links > ul > li > a:hover {
    color: #FFFFFF;
  }
  
  .call {
    display: flex;
    align-items: center;
    padding: 15px 23px;
    border: 1px solid #FFFFFF;
    border-radius: 8px;
  }
  
  .header .call > a {
    color: #fff;
    text-decoration: none;
  }
  
  .call:hover {
    border-color: #3592ff;
  }
  
  .call:hover .call_link {
    color: #3592ff;
  }
  
  .contact {
    width: 520px;
    height: 564px;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 24px;
    padding: 20px;
  }
  
  .contact > div > h2 {
    font-size: 36px;
    font-weight: 500;
    margin: 8px 0;
    text-align: center;
  }
  
  .contact > div {
    margin: auto;
    width: 96%;
  }
  
  @media only screen and (max-width: 600px) {
    .header {
      display: none;
    }
  
    .links > ul {
      display: none;
    }
  
  
    .content h1 {
      font-family: 'Public Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 42px;
      color: #FFFFFF;
    }
  
    .contact {
      width: 100%;
      height: auto;
      padding: 16px 20px;
      align-self: center;
    }
  
    .contact > div > h2 {
      font-size: 24px;
      text-align: center;
    }
  
    .responsiveHeader {
      display: block;
    }
  }
  
  @media only screen and (max-width: 280px) {
    .responsiveHeaderTitle {
      line-height: 32px;
      top: 140px;
    }
  
    .contact > div > h2 {
      font-size: 22px;
    }
  }
  
  @media only screen and (max-width: 768px) {

    /* Header css start */
    .hamburger  {
      position: relative;
    }
    
    .bannerWrap {
      display: none;
   }
   .bannerWrapResponsive {
    background: #7A51AE;
    display: block;
  }
  .bannerImg{
    display: none;
  } 
  .bannerMain {
    flex-direction: column;  
    height: 100%;
    margin-bottom: 40px;
    padding: 0;
}
.btnGrp {
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.textBoxes { 
  flex-direction: column;
}

.responsiveHeader {
  padding: 0 16px;
  background: #7A51AE;
}

.content h1 {
  font-size: 22px;
  line-height: 26px;
  text-align: left;
}

.content h2 {
  font-size: 18px;
  line-height: 21px;
  text-align: left;
}
.content h3 {
  font-size: 14px;
  text-align: left;
}
.content {
 width: 100%;
 
}

.cardBox {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.bottomLine {
  padding: 0;
  text-align: left;
  padding-bottom: 18px;
  font-size: 12px;
}


  }