.heading {
  margin: 30px 0px;
}
.selfcare_plan {
  /* width: 96%; */
  margin: 0px 10px;
}

.monthContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .heading {
    margin: 30px 110px;
  }
}
