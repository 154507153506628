.horizonLine{
    width: 100%;
    /* border: 1px solid; */
    height: 2px;
    background: #dadada;
}
.horizonLineDiv{
    display: flex;
    align-items: center;
    padding: 20px 0;
}
.or{
    margin: 0 10px;
    font-size: 14px;
    
}
.google{
    width: 100%;
    border: 1px solid #dadada;
    text-align: center;
    padding: 8px 0;
    border-radius: 8px;
    cursor: pointer;
}
.fb{
    width: 100%;
    border: 1px solid #dadada;
    text-align: center;
    display: grid;
    place-items: center;
    background-color: #1979F4;
    border-radius: 8px;
    margin: 20px 0 0;
    cursor: pointer;
}

.fb img {
    width: 100%;
}
.signup{
    margin: 20px 0 0 0;
    text-align: center;
}
.signup p{
    font-size: 13px;
    line-height: 19px;
}
.signup span{
    margin: 0 0 0 10px;
    font-size: 13px;
    cursor: pointer;
}

.google_login{
    width: 100%;
    justify-content: center;
    border: 1px solid #dadada;
    font-size: 14px;
}