.coverpage {
  width: 944px;
  height: 288px;
}

.mainWrap {
  display: flex;
  flex-flow: row wrap;
  margin-top: 20px;
  justify-content: flex-start;
}

.coverpage_title {
  display: flex;
  justify-content: center;
  align-items: center;
}
.otpHeading {
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 90px;
}

.logo {
  width: 184px;
  height: 56px;
  margin: 20px 32px;
}
.bg {
}

/* .bg::before {
  position: absolute;
  background-image: url('../../assets/icons/icon/grass.svg');
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  content: '';
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 200px;
  z-index: -9;
} */

.bgPadding {
  padding: 0 0 180px 0;
  position: relative;
}

.outer {
  position: relative;
  width: 100%;
  top: -80px;
}

@media only screen and (max-width: 768px) {
  .logo {
    margin: 8px 10px;
  }

  .header {
    flex-direction: column;
    gap: 0px;
    padding-top: 0px;
  }
}
