.container {
  display: flex;
  flex-wrap: wrap;
}

/* profile */

.paper {
  border-radius: 15px !important;
  padding: 20px;
  margin: 10px;
}

.mainContainer {
  display: flex;
  justify-content: space-between;
}
.inputContainer {
  display: flex;
  flex-direction: column;
}
.groupContainer {
  display: flex;
}
.uploadContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .container {
    justify-content: center;
  }
  /* profile */
  .paper {
    padding: 10px;
  }
  .mainContainer {
    flex-direction: column-reverse;
  }
  .groupContainer {
    flex-direction: column;
  }
}

.linktext {
  font-size: 18px;
  font-weight: 500;
  margin: 20px 12px;
  text-align: center;
  line-height: 20px;
}
