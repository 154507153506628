.appointment input::-webkit-outer-spin-button,
.appointment input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.appointment input[type="number"] {
  -moz-appearance: textfield;
}

.timeSlot option {
  color: green;
}

.timeSlot option:disabled {
  color: red;
}

.addButtuon {
  background-color: #00b091;
  border-radius: 50px;
  margin: 10px;
  padding: 10px 20px;
  border: none;
  color: white;
  font-size: 1rem;
  cursor: pointer;

}

.addButtuon:disabled {
  background-color: #99a5a3;
}


.mainDiv {
  display: flex;
  text-align: left;
}

.marginLeft115 {
  margin-left: 115px;
}

.marginLeft5 {
  margin-left: 5px;

}

.appAppointment {
  width: 320px;
}

@media only screen and (max-width: 768px) {
  .mainDiv {
    flex-direction: column !important;
  }

  .marginLeft115 {
    margin-left: 0px;
  }

  .marginLeft5 {
    margin-left: 0px;

  }
}