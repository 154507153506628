.card {
  width: 960px;
  height: auto;
  min-height: 560px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0px 2px 20px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  margin: auto;
  padding: 0 30px;
  border: 1px solid #f4f4f4;
}

/* .inner{
    width: 960px;
    height: 600px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0px 2px 20px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
    margin: auto;
    padding: 50px;
} */

.bgPadding {
  /* height: 100vh; */
  position: relative;
  /* top: -13vh; */
  padding: 0 0 35px 0;
  overflow: hidden;
}

.upper {
  width: 100%;
  height: 210px;
  display: flex;
}

.left {
  width: 180px;
}
.right {
  width: 678px;
  padding: 0 0 0 24px;
}

.title h1 {
  font-size: 24px;
  font-weight: 500;
}

.content {
  padding: 16px 0;
}

.content p {
  font-size: 16px;
  line-height: 28px;
  color: #2a2a2a;
}

.noOfQuestios {
  display: flex;
  align-items: center;
  margin: 8px 0 0 0;
}

.noOfQuestios_left {
  margin: 8px 8px 8px 0;
}

.label p {
  font-size: 14px;
  line-height: 16px;
  color: #2a2a2a;
}

.totalNumber p {
  font-size: 18px;
  line-height: 21px;
  color: #2a2a2a;
}

.horizontalLine {
  width: 100%;
  height: 2px;
  background-color: #dadada;
  margin: 0 0 32px 0;
}

.lower {
  width: 100%;
  height: 258px;
}

.lowerTitle h1 {
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;
  color: #2a2a2a;
  margin: 0 0 12px 0;
}

.lowerContent ul {
  list-style-type: decimal;
  margin: 0 0 0 25px;
}

.lowerContent ul {
  font-size: 16px;
  line-height: 28px;
  color: #2a2a2a;
}

.button {
  float: right;
  margin: 13px 1px 0 0;
  border-radius: 8px;
}

.bgcard1 {
  width: 920px;
}

.bgcard {
  height: 20px;
  border: 1px solid #f4f4f4;
  margin: auto;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0px 2px 20px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  background-color: #fff;
}

.bgcard2 {
  width: 880px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .card {
    width: 100%;
  }
  .bgPadding {
    /* top: -13vh; */
    position: relative;
  }
}

/* iPads (portrait) ----------- */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .card {
    width: 100%;
  }
  .bgPadding {
    /* top: -9vh; */
    position: relative;
  }
}

@media only screen and (max-width: 768px) {
  .card {
    width: auto;
    height: auto;
    min-height: 715px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0px 2px 20px -2px rgba(24, 39, 75, 0.12),
      0px 4px 4px -2px rgba(24, 39, 75, 0.08);
    margin: auto;
    padding: 0 30px;
    box-sizing: border-box;
    border: 1px solid #f4f4f4;
  }

  .bgcard {
    width: 100%;
  }

  .bgcard1 {
    width: 100%;
  }

  .bgcard2 {
    width: 100%;
  }
  .right {
    width: auto;
  }

  .bgcard1 {
    width: auto;
  }

  .bgPadding {
    position: relative;
    /* top: -10vh; */
  }

  .left img {
    width: 100%;
  }
}
