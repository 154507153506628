.header {
  background-image: url("../Components/Images/Mask\ group.png");
  background-repeat: round;
  height: 740px;
}

.OverBrand {
  background: #ffffff;
  border: 1px solid #ededed;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 4%);
  border-radius: 10px;
  padding: 45px;
  margin: 45px;
}

.OurBrandHeading {
  font-weight: 400 !important;
  font-size: 48px !important;
  line-height: 72px !important;
  color: #242424 !important;
  text-align: center;
  margin-bottom: 20px !important;
  /* margin: 0 !important; */
}

.imgSec img {
  margin-right: 10px;
}

.header>div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hamburger {
  display: none;
  margin: 8px 0 0 8px;
}

.logo {
  margin: 14px 0 0 30px;
  display: flex;
  gap: 30px;
  align-items: center;
}

.header .call {
  border: 1px solid #ffffff;
  border-radius: 8px;
  width: 220px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 14px 30px 0 0;
}

.header .call>a {
  color: #fff;
  text-decoration: none;
}

.links {
  color: #fff;
}

.links>ul {
  display: flex;
}

.links>ul>li {
  list-style-type: none;
  margin: 0 16px;
  font-weight: 500;
}

/* .links > ul > li:hover {
    color: #3592FF;
} */

.links>ul>li>a {
  color: #fff;
  text-decoration: none;
}

.links>ul>li>a:hover {
  color: #3592ff;
}

.header h1 {
  font-size: 64px;
  color: #fff;
  font-weight: 600;
  width: 750px;
  line-height: 100px;
  margin-bottom: 20px;
  align-self: flex-end;
}

.clientSlider {
  background: #ffffff;
  padding: 60px;
}

.clientSlider h3 {
  font-weight: 400;
  font-size: 54px;
  line-height: 72px;
  color: #242424;
  margin: 30px 0;
  text-align: center;
  margin-top: 0;
}

.clientSlider ul {
  list-style: none;
}

.content {
  margin: 28px;
}

.contact {
  width: 520px;
  height: 564px;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  padding: 16px;
}

.contact>div>h2 {
  font-size: 36px;
  font-weight: 500;
  margin: 8px 0;
}

.contact>div {
  margin: auto;
  width: 96%;
}

/* Sponsors */

.sponsors {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 230px;
  display: none;
}

/* Therapy */

.therapy {
  background: #3593ff1a;
  height: 566px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;
  padding: 10px 0;
}

.therapy>div {
  align-self: center;
  z-index: 2;
}

.therapyHeading {
  font-size: 54px !important;
  font-weight: 400;
  align-self: center;
  padding: 0px 20px;
  text-align: center;
}

.therapyHeading>span {
  font-weight: 700;
}

.therapy>div>p {
  font-size: 24px;
  font-weight: 300;
  line-height: 40px;
  align-self: center;
  margin-top: 18px;
  text-align: center;
  padding: 0 10px;
}

.therapy>.cards {
  display: flex;
  gap: 20px;
  justify-content: center;
  /* margin: 0 25px 25px 0 */
}

.rightArrow,
.leftArrow {
  position: absolute;
  z-index: 0;
}

.rightArrow {
  right: 0;
  bottom: 10px;
}

.leftArrow {
  left: 0;
  top: 30px;
}

/* Mental Health Facts */

.mentalHealthFacts {
  display: flex;
  justify-content: space-around;
  margin: 60px 40px;
}

.mentalHealthFacts>div {
  margin: 0 10px;
}

.mentalHealthHeading {
  font-size: 46px !important;
  font-weight: 400 !important;
}

.mentalHealthFacts>div>span {
  margin-left: 30px;
  font-size: x-small;
  color: #6e6e6e;
  display: block;
  margin-top: 5px;
}

.mentalHealthFacts p {
  font-size: 21px;
  font-weight: 400;
  line-height: 40px;
  margin: 20px 0;
}

.mentalHealthFacts>div>img {
  border-radius: 0 20% 0 20%;
}

.healthCards {
  display: flex;
  position: absolute;
  gap: 30px;
}

.healthCards>div {
  display: flex;
  gap: 30px;
  flex-direction: column;
}

/* Samvedna Care */

.samvednaCare {
  display: flex;
  justify-content: space-around;
  background: #ffffff;
  border: 1px solid #ededed;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  padding: 45px;
  margin: 45px;
  gap: 10px;
}

.details {
  margin-left: 15px;
}

.samvednaCare .details p {
  font-weight: 300;
  font-size: 18px;
  line-height: 40px;
  color: #242424;
  width: 681px;
  margin: 20px 0;
}

.samvednaCareHeading {
  display: none;
}

.detailsHeading {
  font-weight: 400 !important;
  font-size: 48px !important;
  line-height: 72px !important;
  color: #242424 !important;
  width: 687px !important;
  margin: 0 !important;
}

.samvednaCare .imgSec {
  /* display: flex;
  gap: 25px;
  margin: 25px 0; */
  /* overflow-x: scroll; */
  width: 700px;
  padding: 8px 0;
  flex-wrap: wrap;
  justify-content: center;
}



.samvednaCare .imgSec>img {
  width: fit-content;
  height: 80px;
  margin-right: 10px;
  /* object-fit: contain; */
}

.samvednaCare .imgSec img {
  margin-right: 10px;
}

.samvednaCare .imgSec>.yes {
  width: 180px;
  height: 80px;
}

.samvednaCare .imgSec::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 50px;
}

.samvednaCare .imgSec::-webkit-scrollbar-thumb {
  background-color: #3592ff;
  border-radius: 50px;
}

.samvednaCare .imgSec::-webkit-scrollbar {
  width: 12px;
  height: 8px;
}

/* Assessments */

.assessments {
  background: #2424240a;
  /* height: 516px; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
}

.assessmentHeading {
  font-size: 54px !important;
  line-height: 72px !important;
  color: #242424 !important;
  font-weight: 400 !important;
  /* width: min-content !important; */
  margin-right: 30px !important;
  align-self: center !important;
  margin-bottom: 20px;
  text-align: center;
}

.assessmentsDetails p {
  font-weight: 300;
  font-size: 18px;
  line-height: 40px;
  color: #242424;
  text-align: left;
  margin: 20px 0;
}

.servicetext {
  font-weight: 300;
  font-size: 18px;
  line-height: 40px;
  color: #242424;
  text-align: center;
  margin: 20px 0;
  padding: 0 10px;
}

.assessments>h2 {
  display: none;
}

.assessments>div {
  z-index: 1;
  display: flex;
  width: 1184px;
  justify-content: space-around;
  flex-direction: column;
}

/* .assessments > div > div {
    display: flex;
    gap: 12px;
} */

.slider {
  display: block;
}

.responsive_carousel {
  display: none;
}

/* Offering */

.offering {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  align-items: center;
  padding: 40px 20px;
}

.offering>div {
  width: 500px;
  height: 600px;
}

.offering img {
  border-radius: 0 20% 0 20%;
  width: 600px;
  height: 500px;
}

.offerings_heading {
  font-weight: 400;
  font-size: 3rem;
  line-height: 72px;
  color: #242424;
}

.offering .list span {
  background: #3592ff;
  border-radius: 30px;
  width: 16px;
  height: 4px;
  display: block;
  position: relative;
  bottom: 6px;
}

.offering .list {
  margin: 20px 0;
}

.offering .list p {
  line-height: 46px;
  font-size: 24px;
}

.offering .list div {
  display: flex;
  align-items: baseline;
  gap: 10px;
}

/* Services */

.services>.slider {
  width: 1184px;
  margin: auto;
  gap: 40px;
}

.services>.btn {
  text-align: center;
}

.services {
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
}

.servicesHeading {
  font-weight: 400;
  font-size: 54px;
  line-height: 72px;
  color: #242424;
  margin: 30px 0;
  text-align: center;
}

/* Testimonial */

.testimonial {
  background: #3592ff;
  display: flex;
  align-items: center;
  padding: 50px 40px;
  justify-content: space-around;
  position: relative;
}

.testimonialHeading {
  color: #fff;
  font-weight: 400;
  font-size: 50px;
  line-height: 72px;
  width: 330px;
}

.testimonial>div {
  background-color: #fff;
  border-radius: 0 50px;
  width: 850px;
  z-index: 1;
}

/* About */

.about {
  display: flex;
  justify-content: space-around;
  padding: 56px;
  gap: 20px;
  background-color: #3593ff1a;
}

.about>div {
  width: 50%;
}

.about .card {
  background: #ffffff;
  border: 1px solid #ededed;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  width: 410px;
  height: 120px;
  display: flex;
  align-items: center;
  margin: 20px 0;
  justify-content: space-evenly;
}

.cardHeading {
  font-weight: 700 !important;
  font-size: 42px !important;
  line-height: 56px !important;
  color: #3592ff !important;
  margin: 20px;
  width: auto !important;
}

.about .card p {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #242424;
  width: 200px;
}

.about .topPara {
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  margin: 20px 0;
}

.about .description {
  font-weight: 300;
  font-size: 18px;
  line-height: 40px;
  color: #242424;
  margin: 20px 0;
}

.aboutHeading {
  font-size: 44px;
  line-height: 72px;
  font-weight: 400;
}

.about>img {
  width: 600px;
  height: auto;
  border-radius: 0 100px 0 100px;
}

.button {
  width: 260px;
  height: 48px;
  font-size: 20px !important;
}

.footer {
  text-align: center;
  padding: 10px 0;
}

@media only screen and (max-width: 600px) {
  .links>ul {
    display: none;
  }

  .OurBrandHeading {
    width: auto;
    font-size: 26px !important;
    font-weight: 400 !important;
    text-align: center;
  }

  .samvednaCare img {
    width: 100%;
    height: 100%;
  }

  .header {
    background-repeat: no-repeat;
  }

  .header h1 {
    font-size: 25px;
    font-weight: 500;
    line-height: 40px;
    width: auto;
    margin-bottom: 10px;
    text-align: center;
  }

  .therapy>.cards,
  .header>div {
    flex-direction: column;
  }

  .services {
    padding: 20px 0;
    display: unset;
  }

  .services {
    flex-direction: column;
  }

  .services .btn {
    display: flex;
    justify-content: center;
    padding: 20px;
  }

  .assessments>div {
    flex-direction: column;
    width: auto;
    /* align-items: center; */
  }

  .assessments>div>div {
    /* overflow-x: scroll; */
    /* flex-direction: column; */
    gap: 12px;
    padding: 10px 0;
  }

  .assessments h2 {
    padding: 0 10px;
  }

  .assessments p {
    padding: 0 20px;
  }

  .servicetext {
    text-align: left;
    padding: 0 20px;
  }

  .therapy,
  .assessments {
    height: auto;
    align-items: center;
  }

  .assessments {
    display: unset;
  }

  .assessments>.leftArrow {
    display: none;
  }

  .therapy {
    padding: 20px 0;
  }

  .testimonial {
    height: auto;
    padding: 50px 0;
  }

  .testimonial>div {
    width: auto;
  }

  .testimonialHeading {
    width: auto;
    font-size: 26px;
  }

  .sponsors {
    overflow-x: scroll;
    gap: 10px;
    margin: 0 10px;
    justify-content: flex-start;
    height: auto;
  }

  .about,
  .offering,
  .testimonial,
  .mentalHealthFacts,
  .samvednaCare {
    flex-direction: column;
  }

  .about {
    padding: 28px;
  }

  .samvednaCare {
    margin: auto;
    padding: 20px;
  }

  .about .description {
    width: auto;
    line-height: 32px;
    text-align: center;
  }

  .healthCards {
    position: unset;
    margin: 30px 0;
    flex-direction: column;
  }

  .healthCards>div {
    flex-direction: row;
  }

  .samvednaCare .img {
    width: auto;
  }

  .samvednaCare .img::-webkit-scrollbar-track {
    box-shadow: unset;
    border-radius: 50px;
  }

  .samvednaCare .img::-webkit-scrollbar-thumb {
    background-color: #80808099;
    border-radius: 50px;
  }

  .samvednaCare .img::-webkit-scrollbar {
    /* width: auto;
    height: auto; */
    width: unset;
    height: 3px;
  }

  .samvednaCare svg {
    width: auto;
    height: auto;
    margin: 16px 0;
  }

  .contact {
    width: 100%;
    height: auto;
    padding: 16px 20px;
    align-self: center;
  }

  .contact>div>h2 {
    font-size: 24px;
  }

  .detailsHeading {
    display: none;
  }

  .samvednaCareHeading {
    width: auto;
    font-size: 26px;
    font-weight: 400;
    text-align: center;
  }

  .samvednaCare .details p {
    width: auto;
    text-align: center;
    line-height: 30px;
  }

  .assessmentHeading {
    display: none !important;
  }

  .assessments h2 {
    width: fit-content;
    margin: auto;
    font-size: 26px;
    line-height: 30px;
    font-weight: 500;
    text-align: center;
    display: block;
    padding: 30px 0 15px 0;
  }

  .offering {
    padding-top: 20px;
  }

  .offerings_heading {
    width: auto;
    font-size: 26px;
    line-height: 33px;
    text-align: center;
  }

  .offering>div {
    width: auto;
    height: auto;
    margin: 0 10px;
    padding: 20px 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
  }

  .offering>img {
    /* width: 90%;
        height: 90%; */
    display: none;
  }

  .offering .list p {
    font-size: 16px;
    line-height: 26px;
    width: fit-content;
  }

  .offering .list span {
    bottom: 4px;
  }

  .about>div {
    width: auto;
  }

  .about .card {
    width: auto;
  }

  .about .card p {
    margin: 0 6px;
    text-align: center;
    line-height: inherit;
  }

  .mentalHealthFacts {
    margin: 20px 4px;
  }

  .aboutHeading,
  .mentalHealthHeading {
    font-size: 32px !important;
    margin: 0 !important;
  }

  .aboutHeading {
    line-height: 33px;
    text-align: center;
  }

  .cardHeading {
    font-size: 32px !important;
    margin-left: 6px !important;
  }

  .therapyHeading {
    font-size: 26px !important;
    margin: 8px;
    text-align: center;
  }

  .servicesHeading {
    font-size: 26px;
    line-height: 33px;
    text-align: center;
  }

  .therapy>div>p {
    margin: 8px;
    text-align: center;
    font-size: 20px;
    line-height: 32px;
  }

  .therapy>.cards {
    gap: 15px;
    width: 94%;
  }

  .hamburger {
    display: block;
  }

  .logo {
    display: none;
  }

  .header>div {
    align-items: flex-start;
  }

  .therapy>.cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .mentalHealthHeading {
    text-align: center !important;
    font-size: 26px;
    line-height: 30px !important;
  }

  .healthCards>h1 {
    font-size: 32px;
  }

  .mentalHealthFacts p {
    text-align: center;
    line-height: 24px;
  }

  .mentalHealthFacts>div>img {
    display: none;
  }

  .about>img {
    display: none;
  }

  .details>.btn,
  .offering .btn {
    display: flex;
    justify-content: center;
  }

  .about .topPara {
    text-align: center;
    line-height: 28px;
  }

  .samvednaCareHeading {
    display: block;
    line-height: 32px;
  }

  .offering .list {
    width: 70%;
    margin: auto;
  }

  .offering .list>div {
    margin: 18px 0;
  }

  .about .btn {
    text-align: center;
  }

  .mentalHealthFacts>div>span {
    font-size: x-small;
    display: block;
    text-align: center;
    margin: 0;
  }

  .services>.slider {
    display: none;
  }

  .slider {
    display: none !important;
  }

  .responsive_carousel {
    display: block;
  }

  .about .card {
    height: 100px;
  }

  .footer {
    font-size: 16px;
  }

  .prevIcon,
  .nextIcon {
    width: 42px;
    height: 42px;
  }
}

@media only screen and (max-width: 280px) {
  .mentalHealthFacts p {
    font-size: 16px;
    line-height: 22px;
  }

  .samvednaCare img {
    width: 100%;
    height: 100%;
  }

  .OurBrandHeading {
    width: auto;
    font-size: 26px !important;
    font-weight: 400 !important;
    text-align: center;
  }

  .samvednaCare .details p {
    line-height: 26px;
    font-size: 15px;
  }

  .assessments h2 {
    font-size: 22px;
  }

  .therapy>div>p {
    font-size: 16px;
    line-height: 22px;
  }

  .therapyHeading {
    font-size: 22px;
  }

  .samvednaCareHeading {
    font-size: 22px;
  }

  .button {
    font-size: 16px !important;
    width: 190px;
    height: 40px;
  }

  .offerings_heading {
    font-size: 22px;
  }

  .offering .list>div {
    margin: 10px 0;
  }

  .testimonialHeading {
    font-size: 20px;
  }

  .servicesHeading {
    font-size: 22px;
  }

  .aboutHeading {
    line-height: 22px;
    font-size: 22px;
  }

  .about .topPara {
    font-size: 16px;
    line-height: 23px;
  }

  .about .description {
    font-size: 14px;
    line-height: 24px;
  }

  .about .card {
    height: 80px;
  }

  .about .card p {
    font-size: 15px;
  }

  .cardHeading {
    font-size: 26px !important;
  }

  .footer {
    font-size: 12px;
  }
}