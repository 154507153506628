.glance {
  display: flex;
  flex-direction: column;
  padding: 25px 18px;
  /* justify-content: space-evenly; */
  align-items: center;
  width: 32%;
  height: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  background-color: #fff;
}

.glance > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.header > h2 {
  font-size: 24px;
  font-weight: 500;
}

.header {
  width: 85%;
}

.header > p {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

@media (max-width: 769px) {
  .glance {
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
    padding: 25px 14px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .glance {
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }
}

@media (max-width: 576px) {
  .glance {
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }
}
