.headerContainer {
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
}

.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}
.MenuEnable {
  cursor: pointer;
  display: none;
}

.request_for_change {
  right: 30px;
  position: absolute;
  top: 30px;
}

@media (max-width: 768px) {
 
  .title {
    font-size: 17px;
    text-align: center;
    padding: 0 10px;
    text-overflow: ellipsis;
    /* white-space: nowrap; */
    overflow: hidden;
  }
  .MenuEnable {
    display: flex;
    align-items: center;
    background: #8e6bba;
    padding: 3px;
    color: #fff;
    border-radius: 10px;
   
  }

  .request_for_change {
    right: 30px;
  }
  .headerContainer { 
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #dbcdee;
    z-index: 1;
    box-shadow: 0px 6px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  }
}
