.upcoming,
.completed,
.cancelled {
  display: flex;
  /* flex-grow: unset; */
  flex-wrap: wrap;
}

.maxLimit {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.completed {
  justify-content: center;
  align-items: center;
}

.doctorDox {
  display: flex;
  justify-content: start;
  flex-direction: row;

}
.doctorBoxLeft {
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 8%);
  border-radius: 20px;
  padding: 24px;
  flex: 1.5;
  max-width: 50%;
    width: 100%;
}
.noDrtext {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #2A2A2A;
  margin-top: 20px

}

.doctorBoxRight {
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 8%);
  border-radius: 20px;
  padding: 24px;
  margin-right: 16px;
  flex: 2.5;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.doctorBoxRight .btnGroup{
 display: flex;
 flex-direction: row;
 justify-content: end;
}
.doctorImg {
  width: 122px;
  height: 115px;
  margin-right: 10px;
}
.doctorImg img{
  width: 122px;
    height: 115px;
    border: 1px solid orange;
    border-radius: 30px;
}
.doctorText .name {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #2A2A2A;
  margin-bottom: 2px;
}

.doctorText .value {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #2A2A2A;
  margin-bottom: 7px;
}

.doctorText .drName {
  font-family: 'Public Sans';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
display: flex;
align-items: center;
color: #2A2A2A;
margin-bottom: 8px;
}

.bottomTex .name{
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #2A2A2A;
  margin-bottom: 3px;
  margin-top: 5px;
}

.bottomTex .aboutUs{
  font-family: 'Public Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #2A2A2A;
  
}

.leftTopBox {
  display: flex;
    justify-content: start;
    align-items: center;
}

.drdetail {
  min-height: 150px;
  max-height: 150px;
  overflow: auto;
}

.drdetail p {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #2A2A2A;
    margin-bottom: 10px;
}

.details {
  width: 80vw;
  margin: 16px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  padding: 12px;
}
.btnGroup {
  text-align: right;
}

@media only screen and (max-width: 768px) {
  .upcoming,
  .completed,
  .cancelled {
    
  justify-content: center;
  }

  .doctorDox {
    flex-direction: column;
    margin-right: 16px;
  }

  .doctorBoxLeft {
    max-width: 100%;
    margin-bottom: 60px;
    margin-top: 10px;
  }
  .doctorBoxRight {
    margin-right: 0px;;
  }

  .details {
    width: 95vw; 
  }

  .btnGroup {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    align-items: center;
    justify-content: center !important;
    background: #fff;
    padding: 10px 0;
  }

  .btnGroup button {
    height: 50px;
    font-size: 15px;
  }





}
