.testCard {
  background: #ffffff;
  width: 90%;
  border-radius: 0px 20px;
  padding: 24px 0px 0px 0;
  margin: 0 auto;
}
.testDesc {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #242424;
  padding: 6px 24px;
  height: 290px;
}

.testDesc > div {
  padding: 10px 20px;
}

.testStar {
  padding: 0 40px;
}

.testAuth {
  border-radius: 0px 20px;
  padding: 12px;
}

.testAuth_purple {
  background: #7a51ae;
}

.testAuth_black {
  background: #242424;
}

.testAuth h4 {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 12px;
  color: #ffffff;
}

.testAuth h5 {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #ffffff;
}

@media only screen and (max-width: 768px) {
  .testCard {
    width: 100%;
  }
}
