.caregiver,
.address {
  /* display: flex; */
  margin: 12px;
}

.size {
  width: 320px;
}

.phoneSec {
  display: flex;
  align-items: center;
  gap: 10px;
}

.profile {
  position: relative;
}

.icon {
  margin-top: -72px;
  margin-left: 134px;
  position: absolute;
}

.general_obv {
  /* display: flex; */
  gap: 65px;
  margin: 20px;
}

.general_obv div {
  /* width: 320px; */
  margin-top: 5px;
}

.ill_history {
  /* display: flex; */
  gap: 30px;
}

.profilePic {
  margin-top: 85px;
}

.profilePic p {
  text-align: center;
}

.patientProfile>.buttons {
  display: flex;
  margin-left: 15px;
  /* position: absolute;
  bottom: 0;
  margin-bottom: 15px; */
}

.patientProfile>.buttons button {
  margin: 0 4px;
}

.patientProfile>.form {
  height: 580px;
}

@media only screen and (min-width: 992px) {
  .general_obv {
    display: flex;
    gap: 65px;
    margin: 20px;
  }

  .ill_history {
    display: flex;
    gap: 30px;
  }

  .caregiver,
  .address {
    display: flex;
    margin: 12px;
  }

  .sa {
    margin-left: 20px;
  }


}

@media only screen and (max-width: 768px) {
  .size {
    width: 100%;

  }

  .patientProfile>.form {
    height: 100%;
  }

  .patientProfile>.buttons {
    margin-left: 0;
    margin-top: 40px;
    justify-content: center;
  }

}