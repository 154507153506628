.box {
    position: relative;
}

.innerBox {
    width: 500px;
    margin: 50px auto;
    text-align: center;
    padding: 10px;
}

.innerBox h2 {
    margin-bottom: 10px;
}

.thanksWrapper {

}

.thanksWrapper p {
    font-size: 16px;
    line-height: 22px;
}