.infocard{
background: #FFFFFF;
width: 80%;
border: 1px solid #E7E7E7;
border-radius: 12px;
margin: 10px;
height: 100px;
cursor: pointer;
}
.infocard p {
    /* margin:auto; */
    text-align: center;
    font-size: 18px;
}
.icon_left div{
    text-align: left;
    margin-left: 5px;
    margin-top: -5px
}
.icon_right div{
text-align: right;
margin-right: 5px;
margin-top:2px
}
.outer_infocard{
    display: flex;
}

@media only screen and (max-width: 768px) {
    .infocard{ 
        width: 100%;
        margin: 0px;
    }
    .infocard p { 
        font-size: 12px;
        padding: 0 10px;
    }
}
