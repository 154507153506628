/* .grid,
.grid2 {
  display: grid; 
  grid-template-columns: repeat(11, 1fr); 
  column-gap: 5px;
} */

/* .grid > div {
  background: #b8b8b833;
  color: #b8b8b8; 
} */

/* .grid2 > div,
.grid > div {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
} */

.selected {
  background-color: #e69a8d !important;
  color: #fff !important;
}

/* .grid2 > div > p {
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  color: #2a2a2a;
} */

/* .grid {
  display: flex;
  width: auto;
  overflow: hidden;
  overflow-x: scroll;
} */

.rounded {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #80808050;
  margin: 0 2px;
  /* pointer-events: none; */
  user-select: none;
}
