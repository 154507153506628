.datepicker_div {
  position: relative;
  width: 250px;
}
.customDate_div {
  color: #000;
  border-radius: 8px;
  border: 1px solid #dadada;
  background-color: #fff;
  padding: 3px;
  display: flex;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-right: 20px;
}
.calender_icon {
  margin: 0 5px;
}
.calender_input {
  margin-left: 8px;
  font-size: 12px;
  align-items: center;
  display: flex;
}

@media (max-width: 769px) {  

  .datepicker_div {
    width: 100% !important;
    /* margin: 0 0 0 26px; */
  }

  .customDate_div { 
    margin-right: 0px;
  }
  
}