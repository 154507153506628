.mood_card {
  padding: 10px;
  width: calc(33.33% - 15px);
}

.printSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  margin-top: 30px;
}

.printFooter {
  text-align: center;
  margin: 10px 0;
}

.username {
  font-size: 17px;
  font-weight: 500;
}

.mood_div {
  padding: 11px;
  /* width: 380px; */
  /* height: 320px; */
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.sleep_div {
  padding: 11px;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: fit-content;
}

#printButton {
  position: relative;
}

.mood_div p {
  margin-left: 25px;
}

.progress_trend_div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.patient_card {
  width: 95%;
  background: #ffffff;
  border-radius: 20px;
  margin-top: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
}

.score_card_div {
  width: 95%;
  display: flex;
  margin-top: 20px;
  gap: 20px;
  margin-bottom: 20px;
}

.score_card {
  width: 50%;
  padding: 10px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
}

.sleepContainer {
  display: flex;
  gap: 15px;
  margin: 10px;
  box-sizing: border-box;
  padding: 10px;
  width: 98%;
}

.MoodIcons {
  font-size: 24px;
}

.row {
  display: flex;
}

.column {
  display: flex;
  padding: 10px;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.bottomheading {
  display: flex !important;
  gap: 1px;
  margin: 5px 10px 10px 20px;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.sleepcss {
  width: 100%;
  border-radius: 20px !important;
}

.sleepcss2 {
  width: 98%;
  padding: 10px;
  margin: 10px;
  border-radius: 20px !important;
}

.circularcss {
  display: flex;
  height: fit-content;
  border-radius: 20px !important;
  justify-content: space-between;
}

.ulcontainter {
  display: flex;
  list-style-type: none;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.listcss {
  margin-top: -7px;
  margin-bottom: 15px;
}

.DailyTrack {
  margin: 10px;
  padding: 5px;
}

.secondPaperContainer {
  margin: 20px 21px;
}

.btncss {
  margin: 50px 0;
}

.mainbottomcard {
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin: 22px;
}

.cardcontainer {
  width: 300px;
  height: 95px;
  border-radius: 15px !important;
  margin: 10px 8px 10px 0;
}

.boxcontainer {
  display: flex;
  flex-direction: row;
}

.iconShadow {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.selectcss {
  margin-left: 20px;
  width: 200px;
}

.datecontainer {
  display: flex;
  border: 1px solid gainsboro;
  width: 400px;
  padding: 11px;
  background: white;
  border-radius: 10px;
  margin-left: 20px;
}

.selectcontainer {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  justify-content: start;
}

.myProgressPrint {
  margin-right: 20px;
}

.Moodtitle {
  font-weight: bold;
  font-size: 20px;
}

.imagebottom {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .mood_card {
    padding: 0;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .sleepContainer {
    display: block;
    margin: 20px;
  }

  .sleepcss {
    margin: 0;
    margin-top: 20px;
  }

  .sleepcss2 {
    width: 98%;
    margin: 0;
    margin-top: 20px;
  }

  .mood_div {
    width: 100%;
  }

  .progress_trend_div {
    flex-direction: column;
    padding: 0 10px;
  }

  .mainbottomcard {
    flex-wrap: wrap;
  }

  .cardcontainer {
    width: 100%;
  }

  .score_card_div {
    flex-direction: column;
    width: 100%;
  }

  .patient_card {
    width: 100%;
  }

  .score_card {
    width: 100%;
  }

  .selectcontainer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 20px;
  }

  .circularcss {
    flex-wrap: wrap;
  }

  .selectcss {
    margin-left: 0;
  }

  #printButton button {
    display: none;
  }

}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .circularcss {
    flex-wrap: wrap;
  }

  #printButton button {
    display: none;
  }

  .ulcontainter {
    width: 50%;
  }

  .DailyTrack {
    width: 100%;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .mood_card {
    padding: 0;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  #printButton button {
    display: none;
  }

  .time {
    font-size: 14px;
  }

  .mood_div {
    width: 100vw;
  }

  .progress_trend_div {
    flex-direction: column;
    padding: 0 10px;
  }

  .progress_trend_Seep_Mood_div {
    display: flex;
    flex-direction: row;
    padding: 0 10px;
  }

  .score_card_div {
    flex-direction: column;
    width: 100%;
  }

  .patient_card {
    width: 100%;
  }

  .score_card {
    width: 100%;
  }

  .selectcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .selectcss {
    width: 90vw;
  }

  .datecontainer {
    width: 375px;
    margin: auto;
  }

  .sleepContainer {
    display: flex;
    flex-direction: column;
    margin-right: 29px;
  }

  .mainbottomcard {
    display: flex;
    flex-direction: column;
  }

  .cardcontainer {
    width: 89vw;
  }

  .secondPaperContainer {
    display: flex;
    flex-direction: column;
  }

  .circularcss {
    display: flex;
    flex-direction: row;
    padding: 0 5px;
    overflow-y: scroll;
  }


  .Moodtitle {
    font-weight: bold;
    font-size: 14px;
  }
}

@media (max-width:768px) {
  .printSec {
    display: none;
  }

  .printFooter {
    display: none;
  }
}