.card {
    background-color: #fff;
    width: 250px;
    height: 215px;
    border: 1px solid #EDEDED;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    z-index: 2;
}

.card > p {
    font-size: 20px;
    line-height: 32px;
    text-align: center;
}

@media only screen and (max-width: 600px) {
   .card {
    width: auto;
    height: 180px;
   }

   .card > p {
    font-size: 15px;
    line-height: 20px;
    margin: 0 6px;
    font-weight: 600;
}

}

@media only screen and (max-width: 280px) {
    .card > p {
     font-size: 13px;
     line-height: 16px;
 }

 .card {
    height: 124px;
   }
 
 }