.card {
  width: 264px;
  height: 300px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  background: #ffffff;
  margin: 10px;
}

.head {
  height: 136px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #3592ff1a;
  border-radius: 20px 20px 0px 0px;
}

.badges {
  position: absolute;
  bottom: -12px;
  left: 10px;
  display: flex;
}

.badges > div {
  margin: 0 2px;
}

.content {
  padding: 8px 14px;
  height: 140px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.name {
  font-size: 18px;
  line-height: 20px;
}

.amount {
  font-size: 16px;
}

.btn {
  width: 232px;
  height: 36px;
  font-size: 14px;
  /* align-self: center; */
}

.btnGroup {
  display: flex;
    justify-content: space-between;
    gap: 10px;
}