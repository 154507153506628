.mainQuestions{
    display: flex;
    align-items: center;
    gap:35px;
}
.addQuestion{
    display: flex;
    align-items: center;
    /* width: 1150px; */
    justify-content: space-between;
    padding: 15px 15px;
}
.quesPartOne{
    display: flex;
    align-items: center;
    /* border: 1px solid red; */
    width: 750px;
    justify-content: space-between;
}
.optFields{
    display: flex;
    width: 775px;
    justify-content: space-between;
}

.optFields svg{
    color: #4caf50
}
.paperContainercss{
    margin: 20px;
}
.h2text{
    margin-left: -14px;
}
.choosefile{
display: flex;
flex-direction: column;
margin-top:-18px
}
.quesimg{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:10px
}