.uploadLabel {
    background-color: white;
    color: black;
    font-family: sans-serif;
    border: 2px solid black;
    border-radius: 0.3rem;
    cursor: pointer;
    margin-top: 1rem;
    padding: 10px 55px;
    width: fit-content;
    height: fit-content;
  }
  
  #file-chosen{
    margin-left: 0.3rem;
    font-family: sans-serif;
  }