.uploadLabel {
	background-color: white;
	color: black;
	font-family: sans-serif;
	border: 2px solid black;
	border-radius: 0.3rem;
	cursor: pointer;
	margin-top: 1rem;
	padding: 10px 15px;
	width: fit-content;
	height: fit-content;
}

#file-chosen {
	margin-left: 0.3rem;
	font-family: sans-serif;
}


/****** CODE ******/

.fileUpload {
	display: block;
	text-align: center;
	font-size: 12px;
	width: 100%;
}

.fileUpload .fileSelect {
	display: block;
	border: 2px solid #898989;
	color: #000000;
	cursor: pointer;
	height: 40px;
	line-height: 40px;
	text-align: left;
	background: #FFFFFF;
	overflow: hidden;
	position: relative;
}

.fileUpload .fileSelect .fileSelectButton {
	background: #898989;
	padding: 0 10px;
	font-weight: 600;
	display: inline-block;
	height: 40px;
	line-height: 40px;
	color: #ffffff;
	text-transform: uppercase;
}

.fileUpload .fileSelect .fileSelectName {
	line-height: 40px;
	display: inline-block;
	padding: 0 10px;
}

.fileUpload .fileSelect:hover {
	border-color: #7a51ae;
	transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-webkit-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
}

.fileUpload .fileSelect:hover .fileSelectButton {
	background: #7a51ae;
	color: #FFFFFF;
	transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-webkit-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
}

.fileUpload.active .fileSelect {
	border-color: #3fa46a;
	transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-webkit-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
}

.fileUpload.active .fileSelect .fileSelectButton {
	background: #3fa46a;
	color: #FFFFFF;
	transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-webkit-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
}

.fileUpload .fileSelect input[type=file] {
	z-index: 100;
	cursor: pointer;
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	opacity: 0;
	filter: alpha(opacity=0);
}

.fileUpload .fileSelect.fileSelect-disabled {
	opacity: 0.65;
}

.fileUpload .fileSelect.fileSelect-disabled:hover {
	cursor: default;
	display: block;
	border: 2px solid #dce4ec;
	color: #34495e;
	cursor: pointer;
	height: 40px;
	line-height: 40px;
	margin-top: 5px;
	text-align: left;
	background: #FFFFFF;
	overflow: hidden;
	position: relative;
}

.fileUpload .fileSelect.fileSelect-disabled:hover .fileSelectButton {
	background: #dce4ec;
	color: #666666;
	padding: 0 10px;
	display: inline-block;
	height: 40px;
	line-height: 40px;
}

.fileUpload .fileSelect.fileSelect-disabled:hover .fileSelectName {
	line-height: 40px;
	display: inline-block;
	padding: 0 10px;
}