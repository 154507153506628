.assesmentResult > h1 {
  font-weight: 400;
  font-size: 16px;
  height: 37px;
  overflow-y: auto;
}

.content {
  padding: 5px 0;
}

.content > h2 {
  font-size: 16px;
  font-weight: 600;
}

.content > p {
  font-weight: 300;
  font-size: 14px;
  margin: 10px 0 10px 0;
  min-height: 60px;
  height: 60px;
    overflow-y: auto;
}

.buttons > button {
  margin-right: 12px;
  margin-top: 12px;
}

.assessment_for_name {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

@media (max-width: 758px) {
  .assesmentResult {
    width: 100%;
    max-width: 100%;
    margin-bottom: 16px;
  }

  .buttons {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  .content > p {
    min-height: auto;
    height: auto;
    margin: 12px 0;
  }
}
