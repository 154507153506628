.service_card {
  width: 560px;
  height: auto;
  /* height: 200px; */
  /* overflow-y: overlay; */
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  border-radius: 20px;
  display: flex;
  padding: 10px;
  margin: 10px;
  background-color: #fff;
  min-height: 250px;
}

.content {
  display: flex;
  flex-direction: column;
}

.icon {
  margin: 6px;
}

.content > p {
  margin: 6px 12px;
  padding: 0 10px;
}

.title {
  font-size: 18px;
  line-height: 21px;
}

.amount {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.description {
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  height: 220px;
  overflow-y: overlay;
}

@media only screen and (max-width: 600px) {
  .service_card {
    flex-direction: column;
    width: 360px;
    padding: 0 28px;
    margin: auto;
    margin-bottom: 10px;
    min-height: 430px;
  }

  .service_icon {
    width: 70px;
    height: 70px;
  }
}

@media only screen and (max-width: 280px) {
  .service_card {
    width: 212px;
  }
}
