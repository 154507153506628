.header {
    display: flex;
    height: 60px;
    align-items: center;
    box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
        0px 4px 4px -2px rgba(24, 39, 75, 0.08);
}

.logo {
  width: 132px;
  height: 40px;
  margin-left: 15px;
}
.search {
  width: 60vw;
  height: 40px;
  margin-left: 7%;
}
.rightHeader {
  display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  margin-right: 20px;
}
.MenuEnable {
  cursor: pointer;
  display: none;
}

.beforeLoader {
  height: 100vh;
  width: 100%;
  position: relative;
}

.beforeHeaderInner {
  width: 400px;
  height: 150px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -75px 0 0 -200px;
  text-align: center;
}

/* Mobile Responsive CSS */

@media only screen and (max-width: 600px) {
  .header {
   flex-direction: row;
   justify-content: space-around;
   position: sticky;
    top: 0;
    /* background-color: #dbcdee; */
    z-index: 1;
    padding: 0 10px;
    box-shadow: 0px 6px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  }
  .MenuEnable {
    display: flex;
    align-items: center;

  }
  .mainLogo{
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  .logo {
    width: 200px;
    height: 50px;
    margin-left: 0px;
    
  }

  .options { 
    justify-content: end;
    gap: 10px;
  }

  .search { 
    display: none;
  }

}
