.innercardSection{
 display: flex;
 gap:15px

}
.questionlines{
    display: flex;
    margin-top: 11px;
    gap:8px
    
}