.progressContainerWrapper {
  position: relative;
  display: inline-flex;
  justify-content: center;
  height: fit-content;
}
.progressTextContainer {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}
