.activity_flex{
    display:flex;
    gap: 50px
}

.activity_div{
    width: 304px;
    display:grid
}
.activity_textbox div{
    border: 1px solid #DADADA !important;
    border-radius: 8px;
    height: 48px;
}
.activity_div select{
    border: 1px solid #DADADA;
    border-radius: 8px;
    height: 48px;
} 

.activity_textfield div{
border: 1px solid #DADADA;
border-radius: 8px;
width: 1056px;
height: 144px;
    
}

.activity_textfield{
margin-top: 20px
}
.activity_per{
    display: flex;
    width: 300px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #00B091;
    margin: auto;
}
.activity_trashicon{
margin: auto;
}
.activity_span_one{
margin-right:10px
}
.activity_span_two{
    margin-left: 15px;
}
.activity_select {
    color: #2A2A2A;
}