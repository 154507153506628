.div_flex{
    /* display:flex; */
    gap:50px;
    padding:10px
}
.detail_div{
width:100%
}
.span_div {
    padding: 10px;
}
.span_div p{
margin-top: 12px;
    color: red;
}
.div_flex .span_div {
    width:80%;
    
}

.span_div TextField{
    width: "320px";
}





@media only screen and (min-width : 992px) {

    .div_flex{
        display:flex;
        gap:50px;
        padding:10px
    }

    .detail_div{
        width:65%
        }
  
   
  
    
  
  
  }