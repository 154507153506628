.chaetmain {
}

.bottomText {
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  margin: 8px 0;
  padding: 4px 8px;
  display: inline-flex;
}

.bottomText > p {
  font-size: 13px;
}

.chartWrapper {
  display: flex;
  gap: 36px;
  flex-direction: row;
  justify-content: space-between;
}

.cardChart {
  margin-top: 20px;
}

.cardChart img {
  width: 100%;
}

.chartHead {
  font-size: 24px;
  padding: 15px 0;
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .chartWrapper {
    flex-direction: column;
  }
  .chartHead {
    font-size: 18px;
  }
}
