.main {

}
.mainHeader {
    display: flex;
    justify-content: space-between;
    background: #FFFFFF;
border: 1px solid #EEEFF4;
padding: 18px 24px;
box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08);
border-radius: 8px 8px 0px 0px;
align-items: center;
}

.subHeader{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 18px 24px;
    border-bottom: 1px solid #ccc;
}



.subCat{

}
.subCatInner {

}
.subCatName {

}
.simpleListTitle {

}
.title {
    padding: 10px 24px;
    border-bottom: 1px solid #ccc;
    font-family: 'Public Sans';
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 14px;
display: flex;
align-items: center;

/* admin primary */

color: #00B091;
}
.block {
    padding: 18px 24px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    border-bottom: 1px solid #ccc;
}
.listBlock {

}
.read , .write, .fullControl{
    display: flex;
    justify-content: center;
}
.write {

}
.fullControl {

}
.simpleList {

}

.mainHeaderSwitch {
   flex-direction: column;
}

.mainHeaderSwitch {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #2A2A2A;
}

 .mainHeaderLeft {
    font-family: 'Public Sans';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 21px;
display: flex;
align-items: center;
color: #2A2A2A;
}

.mainHeaderLeftName {
    font-family: 'Public Sans';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 16px;
display: flex;
align-items: center;
color: #2A2A2A;
}

.scrollbar
{
    height: 390px;
    background: #ffffff;
    overflow-y: scroll;
    margin-bottom: 10px;
}

#styleIdRight::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#styleIdRight::-webkit-scrollbar
{
	width: 0px;
	background-color: #F5F5F5;
}

#styleIdRight::-webkit-scrollbar-thumb
{
	background-color: #00B091;
	border: 1px solid #00B091;
}