.patientSignup {
  width: 736px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  padding: 12px;
  display: contents;
}

.patientSignup>h1 {
  font-weight: 400;
  font-size: 24px;
}

.inputs {
  /* display: grid; */
  grid-template-columns: repeat(2, 1fr);
  row-gap: 20px;
  margin: 16px 0;
}

#sam {
  margin-top: 90px;
}

.mobileNoSec {
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 992px) {
  .patientSignup {
    width: 736px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
    border-radius: 20px;
    padding: 12px;
    /* display:contents; */
  }

  .patientSignup>h1 {
    font-weight: 400;
    font-size: 24px;
  }

  .inputs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
    margin: 16px 0;
    width: 320px;
  }

  #sam {
    margin-top: 90px;
  }
}