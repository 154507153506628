.need_container_desc{
width: 1104px;
background: #FFFFFF;
border: 1px solid #DADADA;
border-radius: 8px;
}
.need_container_title{
margin: 10px;
margin-top: 25px;}

.need_container_title p{
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
}
.need_container_desc p {
font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    margin:15px
}