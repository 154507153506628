.outer_card {
  width: 290px;
  background: #ffffff;
  border: 1px solid #eeeff4;
  box-shadow: 0px 2px 4px -2px rgb(24 39 75 / 12%),
    0px 4px 4px -2px rgb(24 39 75 / 8%);
  border-radius: 8px;
  display: flex;
  padding: 12px;
  align-items: center;
  margin: 10px;
  height: 100px;
}

.card_text {
  height: 62px;
  margin-left: 16px;
  text-align: left;
}
.card_quantity {
  font-weight: 500;
  font-size: 25px;
  margin-top: 20px;
}
.card_title {
  margin: auto;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

@media only screen and (max-width: 768px) { 
 .outer_card {
    width: 100% !important;
    margin: 10px 0;
  }
}
