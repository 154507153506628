.box {
  width: 364px;
  height: 364px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.box img {
  width: 180px;
  height: 180px;
}

.heading {
  font-size: 18px;
  padding: 0 10px;
}

.text {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  width: 250px;
}
