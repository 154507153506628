.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  width: 184px;
  height: 56px;
  margin: 20px 32px;
}

.pleaseReview {
  text-align: center;
}

.pleaseReview p {
  margin-bottom: 10px;
}

@media only screen and (max-width: 768px) {
  .logo {
    margin: 8px 10px;
  }

  .pleaseReview>p {
    margin-bottom: 0;
  }

  .pleaseReview {
    text-align: left;
  }
}

.login_container {
  height: 100vh;
  width: 100%;
  position: relative;
  background-position: bottom !important;
}