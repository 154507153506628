.search_bar {
  background: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  color: #000;
  border-radius: 8px;
  display: flex;
  padding: 3px;
  background-position: 10px center;
}
.search_bar_height {
  padding: 5px;
  /* height: 40px; */
}
.search_bar input {
  border: none;
  width: 100%;
  outline: none;
  background: #fff;
  border-radius: 8px;
  color: #000;
  border-radius: 8px;
  border: none;
  /* background: #FAFAFA; */
  display: flex;
  padding: 3px;
}
.search_bar svg {
  margin: 5px;
}
