.field{
  display:inline-flex;
  margin-right: 1.7in ;
  margin-left:20px;
  width:25%
}

.FilesDragAndDropArea {
  width: 100%;
  height: 200px;
  padding: 50px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  font-size: 24px;
  color: #555555;
  border: 2px rgb(0, 176, 145) dashed;
  border-radius: 12px;
}


