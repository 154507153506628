.assessmentName {
  text-align: center;
  margin: 30px 0 0 0;
  display: flex;
  align-items: normal;
  justify-content: space-between;
}

.q_no {
  font-size: 15px;
  width: 100px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.category h2 {
  height: 25px;
  line-height: 21px;
  font-size: 24px;
  font-weight: 500;

}

.timer {
  font-size: 20px;
  width: 100px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.question {
  margin: 24px 0 0 0;
  text-align: center;
}

.question h1 {
  font-size: 22px;
  font-weight: 450;
  line-height: 26px;
}

.question p {
  font-size: 24px;
  line-height: 28px;
  color: #2a2a2a;
  margin: 24px 0 0 0;
}

.tabbox {
  display: flex;
  justify-content: center;
  padding: 40px 0 0 0;
  flex-wrap: wrap;
  margin: auto;
  /* min-height: 330px; */
}
.minTabbox3 {
  padding: 40px 0 0 0;
  width: fit-content;
  margin: auto;
}

.tab {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.tab {
  border: 1px solid #b6b6b6;
  margin: 0 20px;
  width: 200px;
  height: 176px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.buttons {
  display: flex;
  justify-content: center;
  margin: 30px 0;
  padding: 50px 0px;
}

.questionImagesLeft {
  position: absolute;
  left: 10%;
  top: 20%;
  width: 130px;
  transform: rotate(30deg);
  opacity: 0.3;
  z-index: -1;
}

.questionImagesRight {
  position: absolute;
  right: 5%;
  bottom: 20%;
  width: 180px;
  transform: rotate(-30deg);
  opacity: 0.3;
  z-index: -1;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .questionImagesLeft {
    display: none;
  }

  .questionImagesRight {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .questionImagesLeft {
    display: none;
  }

  .questionImagesRight {
    display: none;
  }

  .assessmentName { 
    flex-direction: column;
  }

  .category h2 {
    height: auto;
    line-height: 30px;
    font-size: 20px;
    font-weight: 500;
  }
  .timer {
    margin-top: 20px;
    width: auto;
    height: auto;
  }
}
