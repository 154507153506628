.switch {
    position: relative;
    display: inline-block;
    width: 90px;
    height: 40px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 32px;
    width: 32px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #7a51ae;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #7a51ae;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(50px);
    -ms-transform: translateX(52px);
    transform: translateX(52px);
  }

  label:last-of-type {
    transform: translateX(0px);
}
  label:first-of-type {
    transform: translateX(0px);
}
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  } 