.toggle,
.slider {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.toggle {
  height: 40px;
  cursor: pointer;
}

.selected {
 
}
.noDataFound {
  padding: 20px;
    text-align: center;
}

.selected .categoryName{
  color: #00B091;
}

.staffListdata {
 
}

.scrollbar
{
    height: 420px;
    background: #ffffff;
    overflow-y: scroll;
    margin-bottom: 10px;
}

#styleId::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#styleId::-webkit-scrollbar
{
	width: 3px;
	background-color: #F5F5F5;
}

#styleId::-webkit-scrollbar-thumb
{
	background-color: #00B091;
	border: 1px solid #00B091;
}

.staffSearch {
  padding: 24px 24px 5px 24px
}

.slider {
  height: 40px;
  margin: 5px 0px;
  width: 100%;
  display: flex;
  align-items: center;
  color: #2A2A2A;
  padding: 20px 24px;

}

.mainTab {
  display: flex;
gap: 24px;
}

.staffListLeft {
  background: #ffffff;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  border-radius: 8px;
  flex: 1 1;
 
}


.staffListRight {
  background: #ffffff;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  border-radius: 8px;
  flex: 2.5 1;
}

.categoryName {
  font-family: 'Public Sans';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 21px;
display: flex;
align-items: center;
color: #2A2A2A;

}


@media (max-width: 768px) { 
  .mainTab {
    flex-direction: column;
  }

  .toggle {
    height: 100%;
  }
}