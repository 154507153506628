.detailHeadingContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 8;
  margin-left: 14px;
}
.innerIconsContainer {
  display: flex;
  margin: 10px;
}
.otherAssContentDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 18px 10px;
  gap: 10px;
}
.listContainer {
  margin: 10px 20px;
}
.innerListContainer {
  margin: 10px 0px;
}
.headingContainer {
  display: flex;
  gap: 8px;
}
.iconText {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 10px;
}

.divider {
  height: 2px;
  background: #e9ecef;
  margin: 20px 10px;
}

.iconsContainer {
  display: flex;
  justify-content: flex-start;
  gap: 40px;
}

.questionRow {
  display: flex;
  border-top: 1px solid #cccc;
  border-bottom: 1px solid #cccc;
  padding: 15px 0px;
}
.assessmentmodal {
  width: 650px;
  /* height: 500px; */
}

.container {
  display: flex;
}

.innercardSection {
  display: flex;
  gap: 15px;
}
.questionlines {
  display: flex;
  margin-top: 11px;
  gap: 8px;
}

@media only screen and (max-width: 600px) {
  .headingContainer {
    flex-direction: column;
  }
  .iconsContainer {
    flex-direction: column;
  }
  .assessmentmodal {
    width: 95%;
    height: fit-content;
  }
  .iconsContainer {
    gap: 0;
    margin: auto;
  }

  .container {
    flex-direction: column;
  }
  .innercardSection {
    flex-direction: column;
  }
}

@media only screen and (max-width: 768px) {
  .iconsContainer {
    gap: 0;
    margin: auto;
    
  }
}
