.box {
  width: 314px;
  height: 314px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
 /* margin-top:30%; */
 /* margin-left: 100%; */
  /* transform:translate(-10%,-10%); */
}

.box img {
  width: 180px;
  height: 180px;
}

.heading {
  font-size: 15px;
}

.text {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  width: 250px;
}

@media only screen and (max-width : 768px) {
  .box {
    width: 364px;
    height: 364px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   /* margin-top:30%; */
   /* margin-left: 100%; */
    /* transform:translate(-10%,-10%); */
  }
  
  .box img {
    width: 180px;
    height: 180px;
  }
  
  .heading {
    font-size: 18px;
  }
  
  .text {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    width: 250px;
  }
  
  
}


 



