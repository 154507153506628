.serviceCardContainer,
.serviceCardContainerJoin {
  width: 25%;
  padding: 10px;
}


.service_card {
  display: flex;
  flex-wrap: wrap;

  margin: 15px;
  justify-content: flex-start;
}

.serviceBooked {
  opacity: 0.6;
}

.serviceBooked::before {
  content: '';
}

.disabledBtnAss {
  color: #fff;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {

  .serviceCardContainer,
  .serviceCardContainerJoin {
    width: 33.33%;
    padding: 10px;
  }
}

/* iPads (portrait) ----------- */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {

  .serviceCardContainer,
  .serviceCardContainerJoin {
    width: 50%;
    padding: 10px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .service_card {
    justify-content: center;
  }

  .serviceCardContainer,
  .serviceCardContainerJoin {
    width: 100%;
    padding: 20px;
  }
}