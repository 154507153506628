:root {
  --bodyColor: #f8f8f8;
}

.ck-content.ck-editor__editable {
  height: 200px;
}

.css-26l3qy-menu {
  width: 86.5% !important;
}