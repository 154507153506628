.btnGroup {
    padding: 0px 30px;
    margin-top: 30px;
    text-align: center;
}

.iconimg {
    text-align: center;
    padding: 20px 0;
}

.reschModalHeader {
    height: auto;
}

.join {
    padding: 0px 36px 16px 36px;
    text-align: left;
}

.resch_app_time {
    margin-bottom: 8px;
    color: #000;
    font-weight: 500;
}

.resch_app_time input {
    padding: 10px;
    width: 100%;
    font-size: 15px;
}

.reschBtnGroup {
    padding: 0px 30px;
    margin-top: 30px;
    display: flex;
    text-align: center;
    justify-content: space-around;
}

.modelContent {
    padding: 0px 36px 16px 36px;
    text-align: left;
}

.reschModal {
    width: 392px;
    height: auto;
    background: white;
    color: white;
    border-radius: 16px;
    box-shadow: 0 5px 20px 0 rgb(0 0 0 / 4%);
    margin: auto;
    margin-top: 6%;
    padding-bottom: 15px;
}

.reshHeading {
    border-radius: 10px;
    margin: 0;
    padding: 36px 36px 4px 36px;
    background: #ffffff;
    font-weight: 600;
    color: #000000;
    font-size: 20px;
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reshModulehead {
    font-family: "Public Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #2a2a2a;
}

.darkBG {
    background-color: rgba(0, 0, 0, 0.2);
    height: 100vh;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}

.centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2 !important;
    background: #00000080;
    width: 100vw;
    height: 100vh;
}

.modal {
    width: 480px;
    padding: 0 30px;
    height: auto;
    background: white;
    color: white;
    border-radius: 16px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
    margin: auto;
    margin-top: 6%;
    padding-bottom: 15px;
}

.modalHeader {
    height: 60px;
    background: white;
    overflow: hidden;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    position: relative;
}

.heading {
    margin: 0;
    padding: 5px;
    padding: 10px 10px 0px 30px;
    background: #ffffff;
    font-weight: 600;
    color: #000000;
    font-size: 20px;
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modalContent {
    font-family: "Public Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    align-items: center;
    text-align: center;
    color: #2a2a2a;
}

/* .modalContent p {
      font-size: 16px;
      color: #000;
      line-height: 24px;
      font-weight: 500;
      display: flex;
    } */
.modalContent p span {
    flex: 1;
    font-size: 15px;
    border: 1px solid #e2e2e2;
    padding: 10px;
    height: 42px;
    margin-bottom: 8px;
    border-radius: 5px;
}

.modalContent p span:first-child {
    border: none;
    padding-left: 0px;
    padding-right: 20px;
    text-transform: uppercase;
    font-size: 14px;
}

.modalActions {
    /* position: absolute; */
    bottom: 2px;
    margin-bottom: 5px;
    width: 100%;
}

.actionsContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.titleHead {
    font-size: 14px;
    margin-top: 14px;
    color: #000;
}

.modelDescription {
    font-size: 16px;
    color: #000;
    line-height: 24px;
    font-weight: 600;
    display: flex;
}

.modelheader {
    display: flex;
    justify-content: space-between;
    width: 80%;
}

.modelheaderInner {}

/* .closeBtn {
        cursor: pointer;
        font-weight: 500;
        padding: 18px 24px;
        border-radius: 8px;
        border: none;
        font-size: 18px;
        color: #2c3e50;
        background: white;
        transition: all 0.25s ease;
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
        position: relative;
        left: 515px;
        bottom: 55px;
        align-self: flex-end;
        margin-top: -7px;
        margin-right: -7px; 
    } */

.closeBtn {
    cursor: pointer;
    font-weight: 500;
    padding: 5px 8px;
    border-radius: 8px;
    border: none;
    font-size: 30px;
    color: #2c3e50;
    background: white;
    transition: all 0.25s ease;
    box-shadow: 0 5px 20px 0 rgb(0 0 0 / 6%);
    position: relative;
    bottom: 0px;
    left: 0px;
}

.closeBtn:hover {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
    /* transform: translate(5px, -5px); */
}

.deleteBtn {
    margin-top: 10px;
    cursor: pointer;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 1.2;
    letter-spacing: 0.02857em;
    padding: 11px 28px;
    border-radius: 5px;
    color: #fff;
    background: rgb(122, 81, 174);
    transition: all 0.25s ease;
    margin-bottom: 10px;
    display: inline-block;
    border: none;
}

.deleteBtn:hover {
    /* box-shadow: 0 10px 20px -10px rgba(255, 62, 78, 0.6); */
    /* transform: translateY(-5px); */
    background: rgb(122, 81, 174);
}

.cancelBtn {
    margin-top: 10px;
    cursor: pointer;
    font-weight: 500;
    padding: 11px 28px;
    border-radius: 12px;
    font-size: 0.8rem;
    border: none;
    color: #2c3e50;
    background: #fcfcfc;
    transition: all 0.25s ease;
}

.cancelBtn:hover {
    box-shadow: none;
    transform: none;
    background: whitesmoke;
}

.app_time input {
    padding: 10px;
    width: 150px;
    font-size: 15px;
}

.app_time {
    margin-bottom: 8px;
    color: #000;
    font-weight: 500;
    height: 42px;
}

.start_time input {
    padding: 10px;
    color: #000;
    font-weight: 500;
    border: 1px solid #e2e2e2;
    width: 200px;
}

.start_time {
    margin-bottom: 8px;
    font-size: 15px;
    color: #000;
    font-weight: 500;
}

.start_time .labeltext {
    width: 50%;
    max-width: 100%;
    flex: unset;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.02857em;
}

.start_time .inputfield {
    width: 100%;
    max-width: 100%;
    flex: unset;
    font-size: 15px;
    border-radius: 5px;
}

.forms {
    padding: 10px 30px;
    display: flex;
    width: 80%;
    justify-content: space-between;
}

.email {
    width: 50% !important;
}

.options {
    width: 50% !important;
}

@media (max-width: 768px) {
    .modal {
        width: 100%;
        max-width: 100%;
        margin-top: 10%;
    }

    .centered {
        width: 100%;
        max-width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }

    .modalContent {
        padding: 10px;
    }

    .forms {
        padding: 10px;
    }
}

@media only screen and (max-width: 768px) {
    .start_time {
        margin-top: 25px;
    }
}