.login_subcontainer {
  background: #fff;
  box-shadow: 0px 4px 12px 4px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  width: 30%;
  /* margin-top: 10px; */
  text-align: left;
  padding: 30px;
  margin: 0 auto;
  /* margin-top:1%; */
  /* position: absolute;
  top: 50%;
  left: 50%; */

  /* transform: translate(-50%, -50%); */
}
.enterPriseHead {
  border-radius: 20px;
  /* width: 30%; */
  /* margin-top: 10px; */
  text-align: left;
  padding: 30px;
  /* margin-top:1%; */
  line-height: 30px;
}

.enterPriseHead h3 {
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  line-height: 36px;
}

.otpHeading {
  font-size: 18px;
    margin-bottom: 20px;
    line-height: 22px;
    font-weight: 500;
}

.otpBox {
  margin-bottom: 15px;
}

.login_subcontainer .heading {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 22px;
  margin-top: 0px;
  font-weight: 400;

  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}

.login_subcontainer .input input[type="text"] {
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 8px;
  width: 100%;
  padding: 15px;
  /* margin-top: 10px; */
  outline-color: #00b091;
}

.login_subcontainer .input {
  width: 100%;
}

.login_subcontainer .input > div > div {
  width: 100%;
}

.login_subcontainer input[type="submit"] {
  width: 100%;
  height: 22px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.login_subcontainer .submit {
  width: 100%;
}

.login_container {
  height: 100vh;
  width: 100%;
  position: relative;
  background-position: bottom !important;
}

.forgot_text a {
  color: #ffffff;
  text-decoration: none;
}

.forgot_text p {
  text-align: right;
  color: #3592ff !important;
  font-weight: 400;
  font-size: 16px;
}

.forgot_text {
  margin-bottom: 16px;
  margin-top: 8px;
}

.error_text {
  margin-bottom: 16px;
  margin-top: 8px;
}

.error {
  font-size: 13px;
  color: red;
}

.error_msg {
  text-align: left;
  padding: 5px 0;
  color: red;
}

.enterprise_poweredby {
  margin-top: 8%;
  text-align: center;
  display: block;
  position: relative;
}

.enterprise_poweredby img {
  margin-top: 5%;
  margin-bottom: 5%;
}
.enterprise_poweredby p {
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #000000;
}

.logo_div {
  display: flex;
  justify-content: center;
  margin: 20px 0px;
}
.footer_container {
  background: #e2f0ff;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  padding: 0px 20px;
}

.enterpriseLogo {
  width: fit-content;
  height: 50px;
  object-fit: contain;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .login_subcontainer {
    width: 80%;
  }
  .footer_container {
    flex-direction: column;
  }

}

@media only screen and (min-width : 667px) {

  .general_obv {
    display: flex;
    gap: 65px;
    /* margin: 20px; */
  }

  .enterPriseHead { 
    padding-top: 0px;
  }

  
}


@media only screen and (max-width : 667px) { 
  .enterPriseHead { 
    padding-top: 0px;
  }
}