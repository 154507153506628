.myAccount {
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08); */
  border-radius: 20px;
  padding: 18px;
}

.myAccount h2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  margin: 25px 0;
}

@media only screen and (min-width: 992px) {
  .myAccount {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    padding: 18px;
    background-color: #fff;
  }

  .myAccount h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    margin: 25px 0;
  }
}
