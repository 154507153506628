.container {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 600px) {
  .container {
    flex-direction: column;
  }

  .container > div {
    margin: auto;
  }

  .title {
    font-size: 15px;
  }

  .details {
    font-size: 13px;
  }
}
