.inputWithIcon>div,
.input>input,
.select>select {
  height: 42px;
  border-radius: 8px;
  outline: none;
  border: 1px solid #dadada;
  font-size: 16px;
  background-color: #fff;
  margin-top: 8px;
}

.textarea textarea {
  width: 100%;
  padding: 8px;
  margin-top: 8px;
  outline: none;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #dadada;
  font-size: 16px;
  font-weight: normal;
}

.input>input,
.select>select {
  width: 100%;
  padding: 8px;
}

/* .setdatepicker { */
/*   height: 70px; */
/*   display: flex; */
/*   flex-direction: column; */
/*   justify-content: space-around; */
/* } */

.input input::-webkit-outer-spin-button,
.input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input input[type="number"] {
  -moz-appearance: textfield;
}

.inputWithIcon input::-webkit-outer-spin-button,
.inputWithIcon input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inputWithIcon input[type="number"] {
  -moz-appearance: textfield;
}

.inputWithIcon div {
  display: flex;
}

.inputWithIcon>div {
  align-items: center;
  border-left: none;
  width: 320px;
  padding-right: 8px;
}

.inputWithIcon .text {
  display: flex;
  align-items: center;
}

.inputWithIcon .text div {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 6px;
}

.inputWithIcon input {
  height: inherit;
  width: 100%;
  align-self: center;
  outline: none;
  font-size: 16px;
  border-left: none;
  border-right: none;
  border-top: 1px solid #dadada;
  border-bottom: 1px solid #dadada;
  border-left: 1px solid #dadada;
  border-radius: 8px 0 0 8px;
  padding: 0 12px;
}

.checkbox>input {
  outline: none;
  margin-right: 6px;
  border: 1px solid #dadada;
  border-radius: 4px;
}

.radio {
  margin: 25px 0;
}

.radio label {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 48px;
  padding: 8px 4px;
  border: 1px solid;
  border-radius: 8px;
}

.radio input[type="radio"]:checked+label {
  border: 2px solid #e69a8d;
  background-color: orange;
}

.radio input[type="radio"] {
  visibility: hidden;
}

.radio>.options {
  display: flex;
  flex-direction: row;
  margin: 8px 0;
  gap: 10px;
  justify-content: space-evenly;
}

.directionset {
  flex-direction: column;
}

.setdatepicker>div {
  width: 100%;
  max-width: 100%;
  font-size: 15px;
}

.setdatepicker input {
  /* background-color: #fff; */
  padding: 10px;
}

.setdatepicker>div:first-child {
  line-height: 45px;
  font-size: 15px;
  letter-spacing: 0.02857em;
}

.setdatepicker>div>input {
  font-size: 15px;
}

@media only screen and (max-width: 768px) {
  .setdatepicker {
    margin-top: 15px;
  }

  .setdatepicker>div {
    width: 100%;

    max-width: 100%;
    font-size: 15px;
  }

  .inputWithIcon>div {
    width: 100%;
  }

  .input>input,
  .select>select {
    width: 100% !important;

  }

  .select,
  .input,
  .directionset {
    width: 100% !important;
  }
}

.label>input+div {
  width: auto;
  background: #ffffff;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  border: 1px solid #dadada;
  border-radius: 8px;
  padding: 14px;
}

.accordion_flex {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.accordion_title {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  margin-left: 10px;
  margin-bottom: 16px;
}

.accordion_opt {
  padding: 2px;
}

.label>input {
  visibility: hidden;
  position: absolute;
}

.label>input:checked+div {
  background: #f19204;
  color: #fff;
}