.heading {
  font-weight: 500;
  font-size: 22px;
}

.papercontainer {
  width: 500px;
  border-radius: 15px !important;
  padding: 20px;
  margin: 10px;
}

.HelpSupportSec {
  padding: 0 15px;
}

@media only screen and (max-width: 600px) {
  .papercontainer {
    width: auto;
  }
}

@media (max-width: 768px) {
  .heading {
    font-size: 18px;
  }
}