.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 18px 0;
  background-position: center;
  background-size: cover;
  cursor: pointer;
}

.border {
  border: 1px solid #DADADA;
}
