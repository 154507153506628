.password {
  height: 568;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}

.password > div {
  margin-bottom: 20px;
}

.btn {
  align-self: flex-end;
}
