.details > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 570px;
  margin: 10px 0;
}

.pricingTab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.pricingTab:not(.first) {
  max-width: 570px;
}
