.general_obv {
  /* display: flex; */
  gap: 65px;
  /* margin: 20px; */
}

.general_obv div {
  width: 320px;
  margin-top: 5px;
}


@media only screen and (min-width : 992px) {

  .general_obv {
    display: flex;
    gap: 65px;
    /* margin: 20px; */
  }
  
}

@media only screen and (max-width : 768px) { 
  .general_obv div { 
    width: 100%;
  }
}
