.badge {
  background: #7a51ae33;
  border-radius: 24px;
  color: #7a51ae;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  height: 34px;
  width: max-content;
  padding: 4px 8px;
}
