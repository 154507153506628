.wrap {
  /* border:1px solid; */
  width: 100%;
  height: fit-content;
  display: flex; 
}

.leftbox {
  width: fit-content;
  height: auto;
  margin: 0 90px;
}

.leftbox ul {
  list-style-type: none;
}
.leftbox ul li {
  margin: 40px 0;
}

.list_wrapper {
  /* border: 1px solid; */
  display: flex;
}
.list_wrapper img {
  margin: 0 10px 0 0;
}
.leftbox .list_wrapper h1 {
  font-size: 24px;
  line-height: 28px;
}

.rightbox {
  width: fit-content;
  height: auto;
  margin: 0 0 0 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.rightbox ul {
  list-style-type: none;
}

.rightbox ul li {
  margin: 10px 0;
}

.rightbox .list_wrapper h2,
p {
  font-size: 16px;
  line-height: 16px;
}

.rightbox .indicators {
  width: 16px;
  height: 16px;
  margin: 5px 10px 0 0;
  border-radius: 50%;
}

.middlebox {
  width: 200px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* Styles */
  .wrap {
    display: flex;
    justify-content: center;
  }
  .leftbox {
    width: fit-content;
    height: auto;
    margin: 0 90px;
  }
  

}

/* iPads (portrait) ----------- */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* Styles */
  .wrap {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .leftbox {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0;
  }

  .middlebox {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .rightbox {
    display: flex;
    justify-content: center;
    width: 100%;
    /* margin-left: 80px; */
  }
  
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .wrap {
    flex-direction: column;
  }
  .leftbox {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0;
  }

  .leftbox ul {
    padding: 0;
    margin: 0;
  }

  .rightbox {
    width: 100%;
    margin: 0;
    justify-content: center;
    display: flex;
  }

  .rightbox ul li {
    display: flex;
    justify-content: center;
  }

  .rightbox .list_wrapper {
    width: 160px;
  }
  .middlebox {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
