.slick-prev:before, .slick-next:before {
    display: none;
}

.slick-prev {
    left: -60px;
    z-index: 1;
    top: 145px;
}

.slick-next {
    right: -10px;
    top: 145px;
}


.slick-active > div {
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 600px) {
    .slick-prev {
        left: 0px;
        top: 150px;
        width: 40px;
        height: 40px;
    }

    .slick-next {
        right: 0px;
        top: 150px;
        width: 40px;
        height: 40px;

    }

    .slick-prev img{ 
        width: 40px;
    }
    .slick-next img { 
        width: 40px;
    }
}