.formContainer {
  border: 1px solid #eeeff4;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  border-radius: 8px;
  background: #ffffff;
  padding: 12px;
  margin-top: 15px;
}

.form {
  width: 100%;
}

.inputs > div {
  display: flex;
  justify-content: space-between;
  margin: 14px 0;
}

.wraper {
  padding: 0 20px;
}
