.container {
  display: flex;
  flex-wrap: wrap;
}

.subscriptionServices {
  overflow-y: scroll;
  height: 380px;
}

.subscriptionServices::-webkit-scrollbar {
  width: 12px;
}

.subscriptionServices::-webkit-scrollbar-track {
  background: white;
}

.subscriptionServices::-webkit-scrollbar-thumb {
  background: #0f0f0f40;
  border-radius: 100px;
  border: 1px solid transparent;
  background-clip: content-box;
}

@media only screen and (max-width: 600px) {
  .container {
    justify-content: center;
  }
}

@media only screen and (max-width: 480px) {
  .subscriptionServices {
    overflow-y: unset;
    height: auto;
  }
}
