.password {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    padding: 30px;
  }

  .password > h2 {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
  }
  
  .password > div {
    margin-bottom: 20px;
  }
  
  .btn {
    align-self: flex-start;
  }
  

  .mainProfile {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    padding: 30px;
  }

  .wrapper {
    margin-bottom: 15px;
  }

  .wrapper > h2 {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;


  }

  .mainProfileSec {
    display: grid;
    row-gap: 10px;
    column-gap: 30px;
    margin-bottom: 18px;
    grid-template-columns: repeat(2, 1fr);
  }

  .name {
    font-size: 14px;
    margin-top: 14px;
  }

  .value {
    font-size: 16px;
    font-weight: 600;
    margin: 10px 0;
  }