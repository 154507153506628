.mood_card {
  padding: 10px;
}

.mood_div {
  padding: 11px;
  width: 100%;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
}
.mainWrap {
  display: flex;
  justify-content: space-between;
  margin: 20px 10px;
}

.chart_div {
}

.mood_div p {
  margin-left: 25px;
}

.progress_trend_div {
  display: flex;
  flex-wrap: wrap;
}

.patient_card {
  width: 95%;
  background: #ffffff;
  border-radius: 20px;
  margin-top: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
}

.score_card_div {
  width: 95%;
  display: flex;
  margin-top: 20px;
  gap: 30px;
  margin-bottom: 20px;
}

.score_card {
  width: 50%;
  padding: 10px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
}
.app_over {
  display: flex;
}
.sub_title {
  margin-top: 15px;
  margin-bottom: 10px;
}


@media (max-width: 769px) { 
  /* .mainWrap {
    flex-direction: row;
  }   */
  .sub_title { 
    margin-bottom: 20px;
  }
  .app_over { 
    flex-direction: column;
  }
}