.expertCard {
  position: relative;
  padding-bottom: 30px;
  margin: 200px 0 0;
}

.card {
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  height: 300px;
  /* margin-top: 100px; */
  position: relative;
  width: 370px;
  background: #ffffff;
}
.frame {
  border: 5px solid #7a51ae;
  border-radius: 100%;
  top: -175px;
  height: 300px;
  left: 35px;
  padding: 0;
  position: absolute;
  width: 300px;
  background-color: #ffffff;
}
.frame img {
  border-radius: 100%;
  height: 100%;
  /* object-fit: fill; */
  width: 100%;
  object-fit: cover;
}

.details {
  top: 50%;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  position: relative;
  width: 100%;
}

.details h3 {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 24px;
  text-align: center;
  color: #2a2a2a;
  margin-bottom: 16px;
}

.details h4 {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 16px;
  text-align: center;
  color: #2a2a2a;
}

.arrowBtn {
  position: absolute !important;
  bottom: 0;
  left: 40%;
  box-shadow: none !important;
  width: 60px !important;
  height: 60px !important;
  background: no-repeat !important;
}
.arrowBtn:hover {
  background: no-repeat;
  box-shadow: none;
}
