.services::-webkit-scrollbar {
  width: 12px;
}

.services::-webkit-scrollbar-track {
  background: white;
}

.services::-webkit-scrollbar-thumb {
  background: #0f0f0f40;
  border-radius: 100px;
  border: 1px solid transparent;
  background-clip: content-box;
}
