  .top {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .name {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
  }
  
  .info {
    font-size: 12px;
    line-height: 14px;
    font-weight: 300;
    margin-top: 5px;
  }
  
  .icons {
    margin: 0 16px 0 6px;
  }
  
  .bottom {
    width: 128px;
    margin: 12px 18px;
    font-size: 12px;
    line-height: 16px;
    position: absolute;
    bottom: 12px;
  }
  
  .bottom p{
    font-size: 12px!important;  
  }
  