

.field_container{
    width:100%
}

.session_button {
    display: flex;
    gap: 8px;
    font-weight: 600;
        margin-top: 25px;
      
}
.session_button .textField{
font-size: 16px;
    text-transform: none;
    padding: "8px 20px 8px 24px";
     align-items: "center",
}

.asses_buttons{
    display: flex;
    gap:35px
}
.tab_con{
background: #FFFFFF;
    border: 1px solid #EEEFF4;
    box-shadow: 0px 2px 4px -2px rgb(24 39 75 / 12%), 0px 4px 4px -2px rgb(24 39 75 / 8%);
    border-radius: 8px;
    margin-top: 20px;
}
.sub_title{
    margin-top: 30px;
        margin-bottom: 20px;
}