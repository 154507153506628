.container{
    /* display:"flex";
    flex-direction: column;
    background-color: yellow;
    gap:12px; */


}
.tableActions{
    display: flex;
    justify-content: space-evenly;
    margin-left: -22px
}

.asstName{
    font-size: 14px;
    color: black;
    margin-top: -6px;
}
.asstMentalName{
    font-size: 16px;
    color:black;
    margin-top: -2px;
}
.EnterPrise{
    font-size: 14px;
    color:black;
    margin-bottom: 64px;
    margin-top: 6px;
}
.Select{
    width: 100%;
    position: absolute !important;
    z-index: 1;
    bottom: 115px;
    right: 0;
    padding: 0 25px;

}
.Select input[type=checkbox]:checked {
    accent-color:white;;
    
    /* background-color: white; */
    /* border: 1px solid black; */
    box-shadow: 0 0 0 1px grey;
  }
    
.Select .css-2613qy-menu {
    /* width: 100px !important; */
}
.Select>div{
        
        padding: 4px 4.5px;
        border-radius: 8px;
        
}

.Select>div:nth-child(2) {
    width: 88.5% !important;
}

/* .Select:nth-child(2) div{
    max-height: 200px;
} */
.Select *{
    text-align: left;
}