.button {
  width: 60px;
  height: 32px;
  border: 1px solid #dadada;
  border-radius: 36px;
  display: flex;
  align-items: center;
}

.thumb {
  width: 24px;
  height: 24px;
  background: #dadada;
  border-radius: 50%;
  margin: 0 4px;
}
