.dropDown {
    display: flex;
    justify-content: end;
    margin: 0 10px;
    position: absolute;
    top: 12px;
    right: 90px;
}

.dropDown select {
    width: 220px;
}


@media (max-width: 769px) {
    .dropDown {
        display: flex;
        justify-content: end;
        margin: 0 10px;
        position: inherit;
    }
    .dropDown select {
        width: 100%;
    }
}