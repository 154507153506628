.paperContainercss {
    padding: 0 10px;
    padding-bottom: 10px;
}
.mainQuestions{
    display: flex;
    align-items: center;
    gap: 10px;

}

.addQuestion{
    display: flex;
    align-items: center;
    
    justify-content: space-between;
    padding: 15px 15px;
}
.quesPartOne{
    display: flex;
    align-items: center;
    /* border: 1px solid red; */
    width: 750px;
    justify-content: space-between;
}
.optFields{
    display: flex;
    width: 700px;
    align-items: center;
    justify-content: space-between;
}

.optFields svg{
    color: #4caf50
}
.trashIcon{
   margin-top: 20px; 
}
.fullQuestion{
    /* margin-left: 10px; */
}


.fileUpload {
    padding: 10px 30px;
    border: 2px solid #000;
    border-radius: 5px;
    cursor: pointer;
}
/* Mobile Responsive CSS */

@media only screen and (max-width: 600px) {
    body{
        overflow-x: hidden;

    }
    .mainQuestions{
        display: flex;
        flex-direction: column;
    }

    .addQuestion{
        display: flex;
    align-items: center;
    width: 320px;
    justify-content: space-between;
    padding: 15px 0px;
    margin-left: 20px;
    margin-top: 20px;
    }
    .optFields{
        display: flex;
    width: 300px;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px;
    }
    .trashIcon{
        margin-top: 20px;
    }
    .fullQuestion{
        margin-left: -65px;
    }

    .formButton{
        margin-top: 30px;
        margin-left: 20px;
    }

    h5 .MuiTypography-root .MuiTypography-h5 .layout_breadcrumbsCss__s-tlu .layout_fontstyle__LoE02 .css-1xszx61-MuiTypography-root{
        margin-left:10px;
    }
  
  }

  /* Media query for Tablet */

  /* @media only screen and (max-width: 800px){

    body{
        overflow-x: hidden;
    }
    .mainQuestions{
        display: flex;
        flex-direction: column;
    }

    .addQuestion{
        display: flex;
    align-items: center;
    width: 320px;
    justify-content: space-between;
    padding: 15px 0px;
    margin-left: -15px;
    }
    .optFields{
        display: flex;
    width: 300px;
    flex-direction: column;
    align-items: flex-start;
    margin-left: -15px;
    }
    .trashIcon{
        margin-top: 20px;
    }
  } */