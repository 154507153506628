.cardContainer {
  display: flex;
  flex-wrap: wrap;
  
}

.heading {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  padding: 18px 0;
}
.resultOverview{
  font-size: 24px;
  padding: 15px 0;
   font-weight: 600
}
.innerResultOverview{
  display: flex;
  padding: 10px;
}
.papercontainer{
  width: fit-content;
}

@media (max-width: 768px) {
  .heading {
    font-size: 20px;
  }
  .resultOverview{ 
    font-size: 20px;
  }
}
@media only screen and (max-width: 600px) {
  .innerResultOverview {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .papercontainer{
    width: 100%;
  }
}
