.labl>input+ div{
    width: auto;
    background: #FFFFFF;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    border: 1px solid #DADADA;
    border-radius: 8px;
    padding: 14px
}
.accordion_flex{
display: flex;
flex-direction: row;
gap: 10px;

}
.accordion_title{
font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-left: 10px;
    margin-bottom: 16px
}
.accordion_opt{
    padding: 2px
}
.labl>input {
    visibility: hidden;
    position: absolute;
}

.labl>input:checked+div {
    background: #F19204;  
    color:#fff
       
}