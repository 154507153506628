.staffListMain {

}
.staffList {

}

.staffSearch {

}

.staffDetailsMain {

}

.rightTabHeader {

}

.scrollbar
{
    height: 300px;
    background: #ffffff;
    overflow-y: scroll;
    margin-bottom: 10px;
}

.scrollbar::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.scrollbar::-webkit-scrollbar
{
	width: 0px;
	background-color: #F5F5F5;
}

.scrollbar::-webkit-scrollbar-thumb
{
	background-color: #000000;
	border: 1px solid #555555;
}

.selectStaffSec {
    text-align: center;
    margin-bottom: 20px;
}
.timeDisable {
    opacity: 0.4;
    cursor: not-allowed;
}
.rightTabHeaderTop {
    padding: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.staffMainData{
    
}

.selectWeek {
    text-align: left;
}

.table {
    width: 100%;
    border-collapse: collapse;
   
}

.thead {
     border: 1px solid #DADADA;
    padding: 20px;
    text-align: center; 
    width: 100px;
}

.tdData {
    text-align: center; 
    width: 100px;
}



.monthDay {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 8px;
  
    color: #2A2A2A;

}

.monthDate {
    display: flex;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #DADADA;
    border-radius: 100px;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;

}
.isLeave {
    background:#f19204;
    color: #fff;
}

.bookedDate {
    background-color: #00B091;
    color: #ffffff;
}

.thDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.tableTr {
    width: 100%;
    display: flex;

}

.selectStaffImg {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding: 40px;
}

.inputFeild {
    display: flex;
    gap: 40px;
    margin-bottom: 32px;
}

.form {
    
}

.container {
    padding: 24px;
    background: #fff;
    border: 1px solid #EEEFF4;
    box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08);
    border-radius: 8px;
}

.workingDays {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 32px;
}

.timeSlot {
    
}

.workingLabel {
    font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
flex: 0.5;
color: #2A2A2A;
}

.checkboxComp {
    flex: 3;
}

@media (max-width: 768px) { 
    .selectStaffImg img {
       width: 100%;
    }

    .rightTabHeaderTop { 
        flex-direction: column;
        gap: 20px;
    }

    .staffMainData{
        overflow-y: scroll;
    }

    .container { 
        overflow-y: scroll;
    }
    .workingDays { 
        flex-direction: column;
    }
    
.checkboxComp {
    overflow-y: scroll;
}
  }