.logout {
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
  width: 200px;
  height: 45px;
  border-radius: 10px;
  background: #fff;
  cursor: pointer;
}

.content {
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 14px;
}

.content > p {
  margin-left: 10px;
  font-size: 16px;
}
