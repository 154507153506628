@import url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Urbanist&display=swap');
@import "./global.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Public Sans", sans-serif;
  color: #2a2a2a;
  background-color: var(--bodyColor);
  height: 100vh;
}

.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  display: none;
}
.ck-editor__editable {
  min-height: 150px !important;
}

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background: white;
}

body::-webkit-scrollbar-thumb {
  background: #0f0f0f40;
  border-radius: 100px;
  border: 1px solid transparent;
  background-clip: content-box;
}
