.section_container {
  padding: 20px;
  width: 80%;
}

.container_wrap {
  padding:  0;
}
.card_container {
  display: flex;
  flex-wrap: wrap;
}
.header_container {
  display: flex;
  align-items: center;
  padding:  15px 0;
}
.header_container .title {
  width: 78%;
}
.header_container .calender_box {
  
}
.sub_title {
  margin-top: 15px;
  margin-bottom: 10px;
}

.breadcrumbsCss {
  text-decoration: none;
  color: gray;
}

.fontstyle {

  margin: 7px 10px !important;

}

.stackbreadcrumbsCss {
  margin: 20px 10px;
  padding-top: 10px;
}
.graph_div {
  margin-top: 30px;
}

@media (max-width: 769px) {
  .leftmenu {
    /* display: none; */
  }
  .fontstyle { 
    font-size: 18px !important;
    text-align: center !important;
    width: 168px;
    display: none;
  }

  /* rajeev css start */
  .card_container { 
    flex-direction: column;
  }
}
