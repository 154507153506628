.carousel {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 350px;
  height: 380px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  background-color: #fff;
}

.carousel > div {
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.carousel > div > div {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}

.carousel h2 {
  font-size: 24px;
  font-weight: 500;
}

@media(max-width:769px){
  .carousel {
    width:100%;
    max-width:100%;
    margin-bottom:10px;

}
}
@media(max-width:576px){
  .carousel {
    width:100%;
    max-width:100%;
    margin-bottom:10px;
  }
}