.image_icon {
  /* background-color: #f2eef7; */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;

}

