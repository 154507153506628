@import "../../global.css";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  background-color: var(--bodyColor);
}

.header > h1 {
  font-weight: 500;
  font-size: 24px;
  padding-left: 20px;
}

.enableHeader {
  display: flex;
  align-items: center;
  gap: 5px;
}
.MenuEnable {
  cursor: pointer;
  display: none;
}

.options {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: auto;
}

@media screen and (min-width: 577px) and (max-width: 769px) {
  .header > h1 {
    font-weight: 500;
    padding-left: 22px;
  }
}

@media (max-width: 576px) {
  .header > h1 {
    padding-left: 15px;
  }
  .MenuEnable {
    display: flex;
    align-items: center;

  }

}

@media (max-width:768px) { 
  .header {
    
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #dbcdee;
    z-index: 1;
    box-shadow: 0px 6px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  }
  .enableHeader h1 {
    font-size: 18px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .MenuEnable {
    display: flex;
    align-items: center;
    background: #8e6bba;
    padding: 3px;
    color: #fff;
    border-radius: 10px;
   
  }
  .headerlogo {
    display: none;
  }
}
