.tbcell{
  display:inline-flex;
  align-items: center;
  gap:5px
}
.edit{
  width:20px;
  height: 20px;
}
/* textButton */
.link{
  text-decoration: none;
}
.mbutton{
  display: inline-block;
  position: relative;
  color: rgb(0, 176, 145);
}

.mbutton:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: rgb(0, 176, 145,0.5);
  transform-origin: bottom;
  transition: transform 0.25s ease-out;
}

.mbutton:hover:after{
  background-color: rgb(0, 176, 145,0.9);
  color: rgb(0, 176, 145);
  visibility:visible; 
  transform: scaleX(1);
  transform-origin: bottom left;
}

.active {
  background-color: rgb(0, 176, 145,0.5);
  color: rgb(0, 176, 145);
  transition: color 100ms ease-out;
  visibility:visible; 
  border-bottom: 3px solid rgb(0, 176, 145);
}