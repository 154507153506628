.popUpContainer {
  width: 330px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 200px;
  justify-content: center;
  outline: none;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0px 7px 8px -4px rgb(0 0 0 / 20%),
    0px 12px 17px 2px rgb(0 0 0 / 14%), 0px 5px 22px 4px rgb(0 0 0 / 12%);
  padding: 32px;
}

@media only screen and (max-width: 758px) {
  .popUpContainer {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .btn {
    width: 140px !important;
  }
}
