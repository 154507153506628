.darkBG {
    background-color: rgba(0, 0, 0, 0.2);
    width: 100vw;
    height: 100vh;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}

.centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2 !important;
    /* background: #80808096; */
    width: 100vw;
    height: 100vh;

}

.modal_card {
    width: 400px;
    height: auto;
    background: white;
    color: white;
    border-radius: 16px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
    margin: auto;
    margin-top: 6%;
    padding-bottom: 15px;
}

.modalHeader {
    height: 56px;
    background: white;
    overflow: hidden;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.modal_checkbox{
    display: flex;
}
.modal_card .heading {
    margin: 0;
    padding: 10px;
       color: #00B091;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
}

.modalContent {
    padding: 10px 30px;
    color: #2c3e50;
    text-align: left;
    font-size: 15px;
}

.modalContent p {
    font-size: 16px;
    color: #000;
    font-weight: 500;
    display: flex;
}

.modalContent p span {
    flex: 1;
    font-size: 15px;
    border: 1px solid #e2e2e2;
    padding: 10px;
    height: 42px;
    margin-bottom: 8px;
    border-radius: 5px;
}

.modalContent p span:first-child {
    border: none;
    padding-left: 0px;
    padding-right: 20px;
    text-transform: uppercase;
    font-size: 14px;
}

.modalActions {
    /* position: absolute; */
    bottom: 2px;
    margin-bottom: 5px;
    width: 100%;
}

.actionsContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.closeBtn {
    cursor: pointer;
    font-weight: 500;
    padding: 18px 24px;
    border-radius: 8px;
    border: none;
    font-size: 18px;
    color: #2c3e50;
    background: white;
    transition: all 0.25s ease;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
    position: absolute;
    right: 0;
    top: 0;
    align-self: flex-end;
    margin-top: -7px;
    margin-right: -7px;
}

.closeBtn:hover {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
    transform: translate(-4px, 4px);
}

.deleteBtn {
    border-radius: 20px;
    margin-top: 10px;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.2;
    letter-spacing: 0.02857em;
    padding: 11px 28px;
    color: #fff;
    background: #00B091;
    transition: all 0.25s ease;
    margin-bottom: 10px;
    display: inline-block;
    border: none;
}

.deleteBtn:hover {
    /* box-shadow: 0 10px 20px -10px rgba(255, 62, 78, 0.6); */
    /* transform: translateY(-5px); */
    background: #00B091;
}

.cancelBtn {
    border-radius: 20px;
    margin-top: 10px;
    cursor: pointer;
    font-weight: 500;
    padding: 11px 28px;
    font-size: 14px;
    border: none;
    color: #ffffff;
    background: #000000;
    transition: all 0.25s ease;
}

/* .cancelBtn:hover {
    box-shadow: none;
    transform: none;
    background: whitesmoke;
} */

.status_btn label{
    margin-left: 1px;
}

@media(max-width:576px) {
    .modal {
        width: 100%;
        max-width: 100%;
        margin-top: 10%;
    }

    .centered {
        width: 100%;
        max-width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }

    .modalContent {
        padding: 10px;
    }
}