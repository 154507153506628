.details_card {
  border: 1px solid #dadada;
  border-radius: 8px;
  width: auto;
  min-height: 320px;
  padding: 10px;
  margin: 10px;
}

.sub_heading {
  font-weight: 500;
  font-size: 18px;
}

.details_card div {
  display: flex;
}

.details_card > div {
  flex-direction: column;
}

.details_card > div > div {
  justify-content: space-between;
}

.details_card .text {
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  margin: 10px 0;
  word-wrap: break-word;
}

.details_card .icons svg {
  margin: 0 6px;
  cursor: pointer;
}
