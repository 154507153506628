.sidebarDementia {
  width: 450px;
  margin: 10px;
}

.progressContainerWrapper {
  position: relative;
  display: inline-flex;
  justify-content: center;
  height: fit-content;
}
.progressTextContainer {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}
.progressValue {
  /* font-size: 30px; */
  /* margin-bottom: 10px; */
}

@media only screen and (max-width: 768px) {
  .sidebarDementia {
    width: 350px;
  }
  .assessment_popup {
    /* width: 80% !important; */
  }
}
