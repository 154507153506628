.app_card{
    background: #FFFFFF;
    border: 1px solid #EEEFF4;
    box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08);
    border-radius: 8px;      
}
.app_container{
display: flex;
flex-wrap: wrap;
margin-left: 10px;
padding: 10px;
}
.app_box{
    width: 320px;
    text-align: left;
    margin-bottom: 12px;
    margin-top: 5px;  
}

.app_box .sec_id{
font-weight: 400;
font-size: 14px;
}
.app_box .sec_det {
    font-weight: 600;
    font-size: 16px;
    margin-top: 6px
}
.app_card .heading{
    padding: 10px;
    margin-top: 10px
}