.social_div{
    display:flex;
    gap:50px;
    margin-top: 20px
}

.form_group_1 input{
width: 320px;
height: 48px;
    background: #FFFFFF;
    border: 1px solid #DADADA;
    border-radius: 8px;
    margin-top:10px;
    padding: 10px;
}
.form_group_2 input {
   width: 520px;
height: 48px;
    background: #FFFFFF;
    border: 1px solid #DADADA;
    border-radius: 8px;
    margin-top: 10px;
    padding: 10px;
}
.add_button{
    background: #2A2A2A;
        border-radius: 100px;
        padding: 8px 15px;
        color:#fff
}
.social_container .row{
margin-top:30px
}
.social_container{
display: flex;
}