.container {
  border: 1px solid #eeeff4;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  border-radius: 8px;
  padding: 12px;
  background-color: #fff;
}

.form > div > div {
  display: flex;
  gap: 20px;
  margin: 10px 0;
}

.form > div > div > p {
  font-weight: 600;
  margin: 2px 0;
}

.form {
  display: flex;
  gap: 240px;
}

.buttons {
  display: flex;
  gap: 20px;
}

/* view page */

.viewCard {
  background-color: #fff;
  display: flex;
  border: 1px solid #eeeff4;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  border-radius: 8px;
  padding: 12px 16px;
}

.description {
  width: 700px;
}
