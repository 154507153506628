.addReports {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    padding: 16px;
    margin: 15px;
}

.addButtuon {
    background-color: #00b091;
    border-radius: 50px;
    margin: 0;
    padding: 10px 20px;
    border: none;
    color: white;
    font-size: 1rem;
    cursor: pointer;
}


@media screen and (max-width: 768px) {
    .serviceName {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}