.line {
  border-top: 1px solid #525151;
  width: 84%;
  margin: auto;
}

.clientSlider {
  background: #ffffff;
  padding: 60px;
}

.clientSlider h3 {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  margin-bottom: 40px;
  text-align: center;
  color: #242424;
}

.stepSec {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #2a2a2a;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 24px 0 0 0;
}

.stepSec span {
  width: 20px;
  height: 20px;
  background: #2a2a2a;
  display: inline-block;
  color: #fff;
  border-radius: 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}

.stepImg {
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
}

.stepImg img {}

.stepTitle {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  align-items: center;
  text-align: center;
  font-size: 18px;
  padding: 0 10px;
  color: #2a2a2a;
}

.howWork {}

.howWork ul {
  display: flex;
  justify-content: space-around;
  list-style: none;
  margin-top: 40px;
  gap: 65px;
}

.howWork ul.desktopView {}

.howWork ul.mobilView {
  display: none;
}

.howWork ul li {
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  width: 21%;
  position: relative;
  height: 100%;
  max-width: 260px;
  height: 260px;
}

.howWork ul li:after {
  content: url("./Images/arrow.svg");
  position: absolute;
  right: -58px;
  top: 45%;
}

.howWork ul li:last-child:after {
  display: none;
}

.HowItWorks {
  background: #7a51b62b;
  padding: 86px 96px;
}

.HowItWorks h3 {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  color: #242424;
}

/* whyShuoldsec */

.whyShould {
  background: #ffffff;
  padding: 60px;
}

.whyShould h3 {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  color: #242424;
  margin-bottom: 40px;
}

.whyShuldSec {
  display: flex;
  justify-content: start;
  gap: 40px;
}

.contentSec p {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  align-items: center;
  color: #2a2a2a;
  margin-bottom: 20px;
}

.contentSec ul {
  list-style: none;
  margin-bottom: 20px;
}

.contentSec ul li {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #2a2a2a;
  margin-bottom: 14px;
  position: relative;
  margin-left: 25px;
}

.contentSec ul li::before {
  content: url("./Images/line.svg");
  position: absolute;
  top: -5px;
  left: -23px;
}

/* assessments sec css start */

.assessments {
  background: #7a51b62b;
  padding: 60px;
}

.assessments h3 {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  margin-bottom: 40px;
  text-align: center;
  color: #242424;
}

/* our services css start */

.ourService {
  background: #ffffff;
  padding: 60px;
}

.ourService h3 {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  color: #242424;
  text-align: center;
  margin-bottom: 40px;
}

/* our dr css */

.ourDoctors {
  background: #2a2a2a08;
  padding: 60px;
}

.ourDoctors h3 {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 47px;
  color: #242424;
  margin-bottom: 40px;
  text-align: center;
}

.ourDrSec {
  display: flex;
  gap: 20px;
}

.ourDrContSec {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ourDrContSec h4 {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #242424;
  margin-bottom: 0px;
}

.ourDrContSec h6 {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #242424;
  margin-bottom: 24px;
}

.ourDrContSec p {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #242424;
}

/* Know our experts css  */

.knowOurExperts {
  background: #2a2a2a;
  padding: 60px;
  position: relative;
}

.bgImage85 {
  position: absolute;
  top: 50px;
  left: 0;
  width: 120px;
}

.bgImage86 {
  position: absolute;
  top: 350px;
  width: 150px;
  right: 0;
}

.knowOurExperts .title {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  margin-bottom: 40px;

  color: #ffffff;
}


.clientSlider {
  background: #ffffff;
  padding: 60px;
}

.clientSlider h3 {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  margin-bottom: 40px;
  text-align: center;
  color: #242424;
}



.clientSliderDetails {
  position: relative;
}

.clientSliderDetails ul {
  list-style: none;
}

/* testimonials css */

.testimonialSec {
  background: #7a51b62b;
  padding: 86px 96px;
}

.testimonialSec h3 {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  color: #242424;
  text-align: center;
  margin-bottom: 40px;
}

.testimonialsCards {
  display: flex;
  justify-content: start;
  gap: 30px;
}

/* faq css start */
.faqSec {
  background: #e7e7e7;
  padding: 86px 96px;
}

.faqSec h3 {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  color: #242424;
  text-align: center;
  margin-bottom: 40px;
}

/* subscription css start */
.viewPackageSubscription {
  background: #7a51b62b;
  padding: 60px;
}

.viewPackageSubscription h3 {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  color: #242424;
  text-align: center;
  margin-bottom: 40px;
}

/* footer css start */
.footerMain {
  padding: 60px;
  background: #303030;
}

.footerSec {
  display: flex;
  justify-content: space-between;
}

.footerOne {
  color: #ffffff;
}

.footerOne h4 {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin-bottom: 8px;
}

.footerOne ul {
  list-style: none;
  margin-bottom: 12px;
}

.footerOne ul li {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.footerOne ul li a {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #ffffff;
  text-decoration: none;
}

.footerTwo h4 a {
  line-height: 20px !important;
}

.footerOne h4 a {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  text-decoration: none;
}

.footerBottom {
  background: #5c5c5c;
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  text-align: center;
  padding: 12px;
}

.followUs {
  text-align: center;
}

.followUsSec {}

.followUsSec h3 {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 10px;
}

.followUsSec ul {
  list-style: none;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  justify-content: center;
}

.followUsSec ul li {
  margin-right: 10px;
}

.followUsSec h4 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
}

.ourDrSecImg {
  width: 100%;
}

.ourDrSecImg img {
  width: 100%;
}

.ourDrSecImg iframe {
  width: 100%;
  height: 375px;
  border-radius: 25px;
}

/* plan css here */

.planAccordion {
  background: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 12px;
}

/* radio btn css here */

.labelPrice {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #2a2a2a;
  margin-bottom: 7px;
  text-align: left;
}

.labelPricePopup {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #2a2a2a;
  margin-bottom: 7px;
  text-align: center;
}

.subLabelPrice {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  color: #2a2a2a;
  margin-bottom: 7px;
  text-align: left;
}

.radioBtn {
  display: flex;
  justify-content: start;
}

.formControl input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: #7a51ae;
  width: 18px;
  height: 18px;
  border: 0.15em solid #bebebe;
  border-radius: 50%;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

.formControl input[type="radio"]::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: #7a51ae;
}

.formControl input[type="radio"]:checked {
  border: 0.15em solid #7a51ae;
}

.formControl input[type="radio"]:checked::before {
  transform: scale(1);
}

.radioBtnTable {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.inputLabel {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #7a51ae;
}

.bookLabel {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #2a2a2a;
}

@media only screen and (max-width: 768px) {

  /* how it works css start */
  .HowItWorks {
    padding: 16px;
  }

  .how_map_works_btn {
    display: flex;
    justify-content: center;
  }

  .HowItWorks h3 {
    margin: 40px 0;
    font-size: 24px;
  }

  .howWork ul {
    flex-direction: column;
  }

  .howWork ul li {
    width: 100%;
    height: 100%;
    max-width: 100%;
    display: flex;
    margin-bottom: 16px;
    gap: 20px;
  }

  .howWork ul li .stepSec,
  .howWork ul li .stepTitle {
    font-size: 14px;
    justify-content: left;
    text-align: left;
    padding-left: 0;
  }

  .howWork ul li::after {
    display: none;
  }

  .howWork ul.desktopView {
    display: none;
  }

  .howWork ul.mobilView {
    display: block;
  }

  .howWork ul.mobilView .stepImg {
    width: 30%;
    padding: 10px;
    margin-bottom: 0px;
  }

  .stepSec span {
    display: flex;
    justify-content: center;
  }

  /* how it works css end */

  .whyShould,
  .assessments,
  .ourService,
  .ourDoctors,
  .knowOurExperts,
  .testimonialSec,
  .faqSec,
  .footerMain,
  .viewPackageSubscription {
    padding: 15px 16px;
  }

  .whyShould h3,
  .assessments h3,
  .ourService h3,
  .ourDoctors h3,
  .testimonialSec h3,
  .faqSec h3,
  .viewPackageSubscription h3 {
    margin: 25px 0;
    font-size: 24px;
  }

  .testimonialSec h3 {
    line-height: 28px;
  }

  .footerSec {
    flex-direction: column;
  }

  .footerOne {
    margin-bottom: 10px;
  }

  .footerOne ul {
    /* margin-bottom: 10px; */
    display: none;
  }

  .ourDrContSec {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .ourDrContSec h4 {
    font-size: 20px;
    margin-bottom: 0px;
    line-height: 20px;
  }

  .ourDrContSec h6 {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .ourDrContSec p {
    font-size: 14px;
    line-height: 25px;
  }

  .ourDrSecImg iframe {
    width: 100%;
    height: 335px;
  }

  .knowOurExperts h3 {
    margin: 18px 0;
    font-size: 24px !important;
  }

  .testimonialsCards {
    flex-direction: column;
  }

  .whyShuldSec {
    flex-direction: column;
  }

  .whyShuldSec img {
    width: 100%;
  }

  .ourDrSec {
    flex-direction: column;
  }

  .bannerMain {
    flex-direction: column;
    height: 100%;
  }

  .btnGrp {
    flex-direction: column;
  }
}