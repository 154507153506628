.rangeInput input[type="number"] {
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 8px;
  width: 100%;
  padding: 15px;
  margin-top: 10px;
  outline: none;
  display: block !important;
}

.rangeInput input[type="number"]::-webkit-outer-spin-button,
.rangeInput input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.rangeInput input[type="text"] {
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 8px;
  width: 100%;
  padding: 15px;
  margin-top: 10px;
  outline: none;
  display: block !important;
}

.radio:hover {
  border: 2px solid#7a51ae !important;
}

.ra {
  /* padding-Top:5px; */
  /* height:120px */
}

/* .checkboxTab input[type="checkbox"]:checked checkboxTab{
    outline:"2px solid #7a51ae";
    color:#7a51ae;
} */

/* input[type="checkbox"] {
    display: grid;
    place-content: center;
  } */

/* input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
  }
  
  input[type="checkbox"]:checked::before {
    transform: scale(1);
  } */

/* .checkTab {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);   */
/* border: 2px solid #dadada;
    border-radius: 8px; */
/*min-width: 220px;
    height: 48px;
    padding: 10px 15px;
    color: #2a2a2a;
    margin: 25px; */
/* } */
/*   
  input[type="text" ] {
    position: absolute;
    left: -9999px;
  } */

/* label {
    display: block;
    position: relative;
    margin: 20px;
    padding: 15px 30px 15px 62px;
    border: 2px solid #DADADA;
    border-radius: 8px;
    color: #111;
    background-color: #fff; */
/* box-shadow: 0 0 20px rgba(0, 0, 0, .2); */
/* white-space: nowrap;
    cursor: pointer;
    user-select: none; */
/* transition: background-color .2s, box-shadow .2s; */
/* } */

/* label::before {
    content: '';
    display: block;
    position: absolute;
    top: 10px;
    bottom: 10px;
    left: 10px;
    width: 15px;
    height: 15px;
    border: 2px solid #dadada;
    border-radius: 100px;
    transition: background-color .2s;
  } */
/*   
  label:first-of-type {
    transform: translateX(-40px);
  }
  
  label:last-of-type {
    transform: translateX(40px);
  }
  
  label:hover, input:focus + label {
    box-shadow: 0 0 20px rgba(0, 0, 0, .6);
    border: 2px solid #7a51ae;
  } */

/* input:checked + label {
    background-color: #ab576c;
    border: 2px solid #7a51ae;
  }
  
  input:checked + label::before {
    background-color: #7a51ae;
  } */

/* @media only screen and (min-width : 992px) {
    .rangeInput input[type="number" ]{
      background: #FFFFFF;
      border: 1px solid #DADADA;
      border-radius: 8px;
      width: 100%;
      padding: 15px;
      margin-top: 10px;
      outline: none;
      display: block !important;
  }
  
  .radio:hover {
      border: 2px solid#7a51ae !important;
  }
  
  .ra{
     padding-Top:25px; 
    height:176px
  }
  }
   */
