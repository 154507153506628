.add_plan{
    display: flex;
    gap:40px
}
.addplan_card{
    background: #FFFFFF;
    border: 1px solid #EEEFF4;
    box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08);
    border-radius: 8px;
    width: 540px
}
.addplan_div{
display: flex;
gap:10px
}
.addplan_title p{
    margin-bottom: 20px;
        margin-top: 5px;
        font-weight: 600;
            font-size: 18px;
            line-height: 21px;
}

.addplan_div div{
    width: 234px;
        height: 40px;
}
.addplan_title  {
    padding: 15px
}
.search_search_bar__ZuaC1.search_search_bar_height__15k30 {
    margin-top: 36px;
}

.score_container_inputs{
    display: flex;
    gap:40px
}
.score_container_title {
    background: #FFFFFF;
    border: 1px solid #EAEAEA;
    border-radius: 8px 8px 0px 0px;
    height: 52px
}

.score_container_desc {
    background: #F8F8F8;
    border: 1px solid #EAEAEA;
    border-radius: 0px 0px 8px 8px
}

.score_container_inputs {
    display: flex;
    margin: 30px;
}

.score_container_title p {
    margin: 15px;
    margin-left: 30px
}

.score_container_desc p {
    margin-left: 30px;
    margin-top: 12px;
}

.score_container {
    margin-top: 15px;
    margin-bottom: 15px;
}

.summary_btn {
    margin-top: 20px;
    margin-bottom: 20px
}
.search_br{
    height:40px;
    margin-top: 33px;
}
.search_sel select {
    width: 250px;
    height: 40px;
}