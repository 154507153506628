.subheader,
.user {
  /* display: flex; */
}

.rightBox {}

.calenderContainer {
  position: absolute;
  height: 55px;
  right: 0;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user {
  align-items: center;
}

.bookService {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 8%);
  border-radius: 16px;
  padding: 13px 20px;
}

.subheader {
  margin: 16px;
  display: flex;
  margin: 10px 0;
  padding: 0 10px;
  width: 100%;
  justify-content: space-between;

}

.noData h3 {
  font-size: 17px;
}

.petientService {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.greeting {
  text-align: left;
  margin-left: 10px;
}

.greeting h2 {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  margin: 0;
}

.greeting p {
  font-size: 14px;
  margin: 0;
}

.bannerImgDesktop {
  text-align: center;
  display: block;
}

.bannerImgDesktop img {
  width: 100%;
  border-radius: 20px;
}

.bannerImgMobile {
  display: none;
}

@media (max-width: 768px) {
  .subheader {
    display: flex;
    flex-direction: column;
    align-items: normal !important;
  }

  .bookService {
    flex-direction: column;
  }

  .subheader .user {
    display: flex !important;
    flex-direction: column;
  }

  .petientService {
    margin-bottom: 15px;
  }

  .rightBox {
    display: flex;
  }

  .bannerImgDesktop {
    display: none;
  }

  .bannerImgMobile {
    display: block;
    text-align: center;
  }
}


@media (min-width: 100px) and (max-width: 400px) {
  .rightBox button {
    width: 100%;
    margin-bottom: 10px;

  }

}