.mood_card {
 
  /* margin: 15px; */
}
.mainHeading{
  display: flex;
  justify-content: space-between;
  align-items: center; 
  /* padding-left: 15px; */
  padding-right: 15px;
  margin-bottom:10px;
}
.mainHeading>span{
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
}
.mainHeading>a{
  font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #7A51AE;
    text-decoration: none;
}

.length {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lengthInner {
  display: flex;
  padding-right: 15px;
  gap: 5px;
  align-items: center;
}
.mood { 
  display: flex;
  border-radius: 5px;
  border: 1px solid #dadada;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin:5px;
}

.moodcard {
  width: 100%;
  height: auto;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #dadada;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0px auto;

}


.moodcard > button:hover {
  background-color: #7a51ae;
}

.mood {
  width: 92px;
  height: fit-content;
  margin: 60px 6px;
  font-size: 14px;
}

.mood > p {
  width: 68px;
  text-align: center;
  font-weight: 300;
  color: #2a2a2a;
}

.title{
  margin: 10px 0;
  /* position: absolute;
  top: 40px;
  left: 35px; */
}

.completed{
  margin:22px 0px;
}


@media(max-width:768px){
  .mainHeading{
    margin-top:15px;
    padding-left:15px;
    padding-right:0px;
  }
  .moodcard {
    width:100%;
    height: auto;
    min-height: auto;
  }
  .mood_card {
    z-index: 0;
  }
}
/*
@media only screen and (max-width : 992px) {
  
  .mainHeading{
    margin-left: 20px;
    margin-top:35px
   }
   .moodcard,
   .mood {
     display: flex;
     border-radius: 5px;
     border: 1px solid #dadada;
     align-items: center;
     justify-content: center;
     flex-direction: column;
     margin:5px;
   }
   
   .moodcard {
     width: 93%;
     height: 260px;
     margin:20px;
   }
   
   .moodcard > div {
     display: flex;
   }
   
   .moodcard button {
     align-self: flex-start;
     /* margin: 12px 0 -178px 12px; */
     /* border-radius: 8px;
     position: absolute;
     top: 80%;
     left: 40px;
   }
   
   .moodcard > button:hover {
     background-color: #7a51ae;
   }
   
   .mood {
     width: 92px;
     height: fit-content;
     margin: 0 6px;
     font-size: 14px;
   }
   
   .mood > p {
     width: 68px;
     text-align: center;
     font-weight: 300;
     color: #2a2a2a;
   }
   
   .title{
     position: absolute;
     top: 20px;
     left: 35px;
   }
   .questionContainer{
     position: absolute;
     top: 54px;
     left: 3%;
   }
  
}
.length{
  position: absolute;
  left: 83.5%;
  top: -2%;
  display:flex;
  gap:5px
}
*/