.AdminReport {
  margin: 40px 10px;
}

.AdminReport h1 {
  margin: 1em 0;
}

.ReportCard_container {
  display: flex;
  flex-flow: wrap;
}

.reportHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}