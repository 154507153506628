/* month picker */

.monthpicker {
  width: 200px;
  color: #2a2a2a;
}

/* .monthpicker::-webkit-scrollbar {
  width: 1em;
}

.monthpicker::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.monthpicker::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
} */

/* common styles */

.display,
.select,
.year {
  width: inherit;
  min-width: 150px;
  max-width: 400px;
  user-select: none;
  border-radius: 8px;
}

.disabled {
  opacity: 0.6;
    cursor: not-allowed !important;
}

.display,
.year {
  display: flex;
  align-items: center;
}

/* Date Picker Box */

.display {
  height: 36px;
  border: 1px solid #dadada;
  justify-content: space-between;
  cursor: pointer;
}

.text {
  margin: 0 0 0 8px;
}

.appointment {
  margin-right: 8px;
}

/* Select Box */
.select {
  margin-top: 10px;
  height: 280px;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
  overflow-y: scroll;
  position: absolute;
  z-index: 1;
  background-color: #fff;
}

::-webkit-scrollbar {
  /* display: none; */
}

.year {
  height: 36px;
  font-size: 18px;
  background: #fff;
  justify-content: space-around;
  width: 100%;
}

.months {
  display: flex;
  flex-direction: column;
}

.month {
  cursor: pointer;
  text-align: left;
  margin: 7% 10%;
}

@media (max-width: 576px) {
  .monthpicker {
    width: 100%;
    max-width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .select {
    width: auto;
  }
}
