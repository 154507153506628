.formContainer {
  border: 1px solid #eeeff4;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  border-radius: 8px;
  background: #ffffff;
  padding: 12px;
}
.addButtuon {
  background-color:#2A2A2A;
  border-radius: 100px;
  padding: 10px 20px;
  border: none;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  width:"100px";
  margin:"0 4px 0 0";
  font-weight: 500;
    font-size: 0.875rem;
}

.form {
  width: 100%;
}

.inputs > div {
  display: flex;
  justify-content: space-between;
  margin: 14px 0;
}
