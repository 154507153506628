.card_assessment {
  width: calc(33.33% - 60px);
  border: 1px solid #fff;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0px 2px 20px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  margin: 10px;
  padding: 15px 20px;

  /* flex: 1; */
}

.card_assessment_plan {
  width: calc(31% - 10px);
  border: 1px solid #fff;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0px 2px 20px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  margin: 10px;
  padding: 15px 20px;

  /* flex: 1; */
}

.homeCard {
  width: calc(95.33% - 60px);
}

.card_assessment:nth-child(2) {
  order: -1;
}


.card_assessment:hover {
  background-color: rgb(236, 233, 233);
}

.card_assessment_plan {}

.visible {
  visibility: visible;
}

.inVisible {
  visibility: hidden;
}

.card_dashboard {
  width: calc(31% - 10px);
  border: 1px solid #fff;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0px 2px 20px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  margin: 10px;
  padding: 16px;
  height: 352px;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
}

.card_dashboard:nth-child(2) {
  order: -1;
}

.card_dashboard:hover {
  background-color: rgb(236, 233, 233);
}

.skeleton_card_dashboard {
  width: calc(24% - 10px);
  border-radius: 20px;
  background-color: #fff !important;
  box-shadow: 0px 2px 20px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  margin: 10px;
  height: 200px;
  padding: 16px;
}

.skeleton_card_assessment {
  width: calc(33.33% - 60px);
  border-radius: 20px;
  background-color: #fff !important;
  box-shadow: 0px 2px 20px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  margin: 10px;
  padding: 15px 20px;
  height: 200px;
}

.img_icon_div {
  text-align: center;
}

.img_icon {
  width: 120px;
  height: 120px;
  margin: auto;
}

.card_title_box {
  margin: 15px 0 0;
}

.card_title {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  line-height: 25px;
}

.card_btn_box {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.card_btn_box>Button {
  font-size: 15px;
}

.progress_bar_label_div {
  text-align: left;
}

.progress_bar_label {
  font-size: 10px;
  margin-bottom: 4px;
}

.flipCard {
  height: 150px;
  /* perspective: 1000px; */
}

.flipCard_plan {
  height: 150px;
  /* perspective: 1000px; */
}

.flipCard_inner {
  position: relative;
  width: 100%;
  height: 100%;
  /* text-align: center; */
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flipCard:hover .flipCard_inner {
  transform: rotateY(180deg);
}

.flipCard_front,
.flipCard_back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flipCard_front {}

.flipCard_back {
  transform: rotateY(180deg);
}

.instruction_card {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .card_dashboard {
    width: calc(50% - 25px);
    max-width: 100%;
  }

  .card_assessment {
    width: calc(50% - 20px);
    margin: 10px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .card_dashboard {
    width: calc(33.33% - 20px);
    max-width: 100%;
  }

  .card_assessment {
    width: calc(33.33% - 20px);
    margin: 10px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .dashboard>div>div {
    flex-direction: column;
  }

  .card_assessment {
    width: 100%;
    margin-top: 10px;
  }

  .card_dashboard {
    width: 100%;
  }

  .dashboard {
    width: 100%;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }

  .article,
  .assesmentResult {
    width: 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .card_assessment:nth-child(2) {
    order: -1;
  }
}