.container {
  height: 16px;
  background: #ffce39;
  margin: 25px 0 40px 0;
  border-radius: 24px;
  position: relative;
}

.rangeContainer {
  width: 33%;
  height: 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.level {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.green {
  background: #50cd89;
  width: 33.33%;
  height: 16px;
  border-radius: 24px 0 0 24px;
}

.red {
  background: #ff5a5a;
  width: 33.33%;
  height: 16px;
  border-radius: 0 24px 24px 0;
}

.range {
  background: #ffffff;
  border: 1px solid #dadada;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  /* position: absolute; */
  /* left: 145px; */
  /* top: -8px; */
}

@media (max-width: 576px) {
  .container {
    width: 100%;
    max-width: 100%;
  }
}
