.toggle,
.slider {
  display: inline-block;
  align-items: center;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.toggleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.toggle {
  /* height: 56px; */
  margin-bottom: 16px;
  border: 1px solid #dadada;
  border-radius: 20px;
  /* width:270px; */
  margin-top: 10px;
  text-align: center;
  margin-right: 10px;
  background-color: #fff;
}

.slider {
  border-radius: 20px;
  padding:4px 8px;
  /* height: 28px; */
  margin: 4px;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .toggle,
  .slider {
    display: inline-flex;
    align-items: center;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    padding: 5px;
    /* width:50%; */
  }

  .toggleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  

  .toggle {
    display: inline-block;
    margin-bottom: 16px;
    border: 1px solid #dadada;
    border-radius: 20px;
    margin: 0 10px;
  }

  .slider {
    border-radius: 20px;
    padding: 0 8px;
    height: 28px;
    margin: 0;
    cursor: pointer;
  }
}

