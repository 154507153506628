.signup_subcontainer {
  /* background: #ffffff;
    box-shadow: 0px 4px 12px 4px rgba(0, 0, 0, 0.08); */
  border-radius: 20px;
  min-width: 28%;
  margin: auto;
  width: 50%;
  text-align: left;
  /* padding: 30px; */
  margin-top: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.signup_subcontainer .heading {
  font-size: 24px;
  margin-bottom: 22px;
  margin-top: 0px;
}
/* .signup_subcontainer input[type="text"] {
    background: #ffffff;
    border: 1px solid #dadada;
    border-radius: 8px;
    width: 100%;
    padding: 15px;
    margin-top: 10px;
    outline-color: #00b091;
  } */
.signup_subcontainer .input {
  width: 100%;
}
.signup_subcontainer input[type="submit"] {
  /* background: #00B091; */
  border-radius: 8px;
  width: 100%;
  border: none;
  color: #ffffff;
  padding: 12px;
  font-weight: 500;
  font-size: 18px;
}
.signup_subcontainer .submit {
  width: 100%;
}
.signup_container {
  margin-top: 15%;
  width: 100%;
  position: relative;
  background-position: bottom !important;
}
.forgot_text p {
  text-align: right;
  /* color: #00B091; */
  font-weight: 500;
  font-size: 14px;
}
.forgot_text {
  margin-bottom: 16px;
  margin-top: 8px;
}
.error_text {
  margin-bottom: 16px;
  margin-top: 8px;
}

@media only screen and (max-width: 480px) {
  .signup_subcontainer {
    width: auto;
    margin-top: 320px;

  }
}
