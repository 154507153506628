.container {
  background-image: url('../icons/icon/leaves.svg');
  background-repeat: no-repeat;
}

.socialIcons {
  margin: 10px 18px 12px 22px;

}

.socialIcons h1 {
  font-size: 14px;
  text-align: center;
}

.socialIcons ul {
  display: flex;
  list-style: none;
  align-items: center;
  margin-top: 5px;
  justify-content: center;
}

.socialIcons li {
  margin-right: 10px;
}

.socialIcons li img {
  width: 30px;
  height: 30px;
}

.top {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.name {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
}

.info {
  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
  margin-top: 5px;
}

.icons {
  margin: 0 16px 0 6px;
}

.bottom {
  width: 128px;
  margin: 12px 18px;
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  bottom: 12px;
}

.bottom p {
  font-size: 12px !important;
}

.notHeading {
  color: #061C28;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 16px;

}

.notiFicationList ul {
  list-style: none;
}

.notiFicationList ul li {
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  margin: 10px 0;
  background: #f7f0ff;
  padding: 10px;
  line-height: 18px;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .socialIcons {
    position: absolute;
    top: 85%;
    left: 50%;
    -ms-transform: translate(-50%, -85%);
    transform: translate(-50%, -85%);
    margin: 0;

  }
}