.title {
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  margin: 10px;
}


@media only screen and (max-width: 280px) {
  .card {
    width: 190px!important;
  }
 
}

@media only screen and (max-width: 768px) {

  .mainCon {
    width: 100% !important;
    margin: 50px 0 !important;
  }
 
}

