.tab_container{
    text-transform: none;
    width:650px;
}

.scrollbar {
    
}

@media screen and (max-width: 768px) {
    .tab_container{
        width:auto;
    }
}