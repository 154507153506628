.popUpContainer {
  width: 480px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 260px;
  outline: none;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0px 7px 8px -4px rgb(0 0 0 / 20%),
    0px 12px 17px 2px rgb(0 0 0 / 14%), 0px 5px 22px 4px rgb(0 0 0 / 12%);
  padding: 32px;
}

.moNoSec {
  display: flex;
  gap: 20px;
}

.mobileCountry {
  display: flex;
  gap: 10px
}

.countryCode {}

.popUpContainerEnterPrise {
  width: 480px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0px 7px 8px -4px rgb(0 0 0 / 20%),
    0px 12px 17px 2px rgb(0 0 0 / 14%), 0px 5px 22px 4px rgb(0 0 0 / 12%);
  padding: 32px;
}

.mainHeader {
  width: 100%;
  border-top-left-radius: 19px;
  border-top-right-radius: 19px;
  color: white;
  font-size: 18px;
  font-weight: 600;
  padding: 5px;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.mainLogo {
  margin-right: 10px;
  margin-top: 7px;
}

.loginPopup {
  width: 600px;

  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0px 7px 8px -4px rgb(0 0 0 / 20%),
    0px 12px 17px 2px rgb(0 0 0 / 14%), 0px 5px 22px 4px rgb(0 0 0 / 12%);
  padding: 32px;
}

.mobileNo {
  width: 100%;
}

@media only screen and (max-width: 758px) {
  .popUpContainer {
    width: 100%;
  }

  .popUpContainerEnterPrise {
    width: 100%;
  }

  .loginPopup {
    width: 100%;
    padding: 10px;
  }

  .mainHeader {
    gap: 13px;
  }
}

@media only screen and (max-width: 600px) {
  .btn {
    width: 140px !important;
  }
}