.mainHeading {
  margin-left: 0;
  margin-top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0px;
  padding-right: 15px;
}
.mainHeading > span {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
}
.mainHeading > a {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #7a51ae;
  text-decoration: none;
}
.activityCard {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-evenly;
  border: 1px solid #dadada;
  border-radius: 8px;
  padding: 20px;
  margin-top: 10px;
  align-items: center;
}

.activityToggle {
  display: flex;
  align-items: center;
}

.divide {
  width: 90%;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.divide > p {
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 2px;
}
.divide > h5 {
  font-weight: 500;
  font-size: 16px;
  margin-right: 5px;
}
.divide > div > span {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.icon {
  width: 10%;
  height: 50px;
  margin-right: 5px;
}
.postfooter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 10px;
}
.postfooter > p {
  margin-left: 10px;
}
.statuscss {
  width: 80px;
}

.statuscss {
  display: flex;
  align-items: center;
}

.button_container {
  display: flex;
}

@media screen and (max-width: 768px) {
  .activityCard {
    width: 100%;
    max-width: 100%;
    padding: 10px;
    align-items: center;
    min-height: auto;
    height: auto;
    /* flex-direction: column; */
  }
  .mainHeading {
    padding-right: 0px;
  }
  .icon {
    width: 48px;
    max-width: 100%;
  }
  .divide {
    width: 100%;
    padding: 0 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .button_container {
    width: 100px;
  }
  .moodCartSelf {
    z-index: 0;
    position: relative;
  }
}

/*
@media only screen and (max-width : 992px) {
  .mainHeading{
    margin-left: 20px;
    margin-top: 20px;
  }
  .activityCard {
    width: 93%;
    height: fit-content;
    /* display: flex; */
/*   justify-content: space-evenly;
    border: 1px solid #dadada;
    border-radius: 8px;
    margin:20px;
    padding: 20px;
  }
  
  .activityToggle {
    display: flex;
    align-items: center;
  }
  
  .divide {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap:10px
  }
  
  .divide > p {
    width: 450px;
    margin-top: 4px;
  }
  .icon{
    position: absolute;
    left: 6%;
  }
  
  
}
*/
