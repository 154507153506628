.progressContainerWrapper {
    position: relative;
    display: inline-flex;
    justify-content: center;
    height: fit-content;
  }
  .progressTextContainer {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
  }
  .noData {
    font-size: 12px;
  }

  .levelDiv {
    display: flex;
    align-items: center;
    padding: 0 10px 0 0;
  }

  .levelDiv   h4 {
    font-family: 'Public Sans';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 21px;
display: flex;
align-items: center;

/* Primary Text */

color: #2A2A2A;
  }

  