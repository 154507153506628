.container {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 768px) {
  .container {
    display: block;
    flex-direction: column;
    align-items: center;
  }
}
