.assessment_card {
    width: 560px;
    min-height: 280px;
    height: auto;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    display: flex;
    padding: 10px;
    margin: 10px;
    background-color: #fff;
}

.content {
    display: flex;
    flex-direction: column;
}

.icon {
    margin: 6px;
}

.content > p {
    margin: 6px 12px;
}

.content .title {
    font-size: 18px;
    line-height: 21px;
}

.amount {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

.content .description {
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
}

@media only screen and (max-width: 600px) {
    .assessment_card {
        flex-direction: column;
        width: 300px;
        min-height: 400px;
        margin: auto;
        margin-bottom: 10px;
    }

    .icon > img {
        width: 70px;
        height: 70px;
    }
}

@media only screen and (max-width: 280px) {
    .assessment_card {
        width: 212px;
    }
}