.plansContainer {
  background: #ffffff;
  box-shadow: 0px 4px 12px 4px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  min-width: 20%;
  margin: auto;
  text-align: center;
  padding: 30px;
  position: absolute;
  top: 40%;
  left: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.assessmentCardInside {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 20px;
  box-shadow: 0 2px 20px -2px rgb(24 39 75 / 12%),
    0 4px 4px -2px rgb(24 39 75 / 8%);
  margin: 10px;
  padding: 15px 20px;
  width: 280px;
}

.assessmentCardInside h4 {
  text-align: center;
  padding: 8px 0;
}

.assessmentCardConatiner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.service_card {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

@media only screen and (max-width: 768px) {
  .plansContainer {
    position: inherit;
  }

  .assessmentCardConatiner {
    flex-direction: column;
  }

  .assessmentCardInside {
    width: inherit;
  }
}