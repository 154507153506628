.gridcard {
  position: relative;
}
.cardMedia {
  position: absolute;
  width: 132px;
  top: -70px;
  left: 66px;
  border: 1px solid #ff881b;
  border-radius: 10px;
}
.CardContent {
  bottom: 0px;
  position: absolute;
  width: 100%;
  padding-bottom: 16px;
}

.card {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  width: 238px;
  height: 198px;
  position: relative;
  margin-top: 80px;
}

.frame {
  width: 133px;
  height: 133px;
  border: 2px solid #e69a8d;
  position: relative;
  bottom: 60px;
  left: 54px;
  border-radius: 16px;
  padding: 0px;
}

.frame img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 14px;
}

.details {
  display: flex;
  flex-direction: column;
  width: 90%;
  position: absolute;
  bottom: 14px;
  margin: 0 12px;
}

.details > p {
  margin: 6px 0;
}
.mainDiv {
  padding: 0 15px;
  margin: 10px 0;
}


@media (max-width: 768px) { 
  .card { 
    width: 100%;
  }
  .frame {
    transform: translate(-50%, -50%);
    top: 0%;
    left: 50%;
  }
}

