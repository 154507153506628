.profileContainer {
  display: grid;
  grid-template-columns: repeat(2, 0.6fr);
  grid-gap: 20px 30px;
}

.copyUrl {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}