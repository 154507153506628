.reportsCard {

  height: 218px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 16px;
}

/* session Id */
.reportsCard>p {
  color: #e69a8d;
  background-color: #e69a8d1a;
  width: 88px;
  border-radius: 20px;
  text-align: center;
  padding: 2px 4px;
}

.add_report {
  text-align: right;
}

.reportsCard>h2 {
  font-size: 18px;
  font-weight: 400;
  margin: 12px 0;
}

/* date */
.reportsCard>div>p {
  font-size: 14px;
  font-weight: 300;
  margin: 0 0 10px 4px;
}

.reportsCard>div {
  display: flex;
  margin-bottom: 6px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  justify-content: right;
}

.customerName {
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 0 !important;
  flex-direction: column;

}

.customerName h2 {
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 10;
}