.inputHorizontal {
  margin-top: 8px;
}

.avatar {
  margin: 0 30px;
}

.form {
  width: 70vw;
}

.button {
  float: right;
  margin-top: 10px;
}

@media only screen and (min-width: 992px) {
  .profile,
  .personal_details > div,
  .personal_details > div > div,
  .address > div,
  .address > div > div {
    display: flex;
  }

  .personal_details > div,
  .address > div {
    flex-direction: column;
  }

  .personal_details > div > div,
  .address > div > div {
    flex-direction: row;
  }

  .personal_details > div > div > div,
  .address > div > div > div {
    width: 100%;
  }
}

@media only screen and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
  .profile {
    /* display: flex; */
    margin-left: 100px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .profile {
    /* display: flex; */
    margin-left: 100px;
  }
}
