.sideRes {
  background-color: yellow !important;
}

/* subscription */
/* #a8 {
  pointer-events: none;
} */
/* payment */
/* #a6 {
  pointer-events: none;
} */
/* enterprise */
#a3 {
  /* pointer-events: none; */
}

/* report */
#a10 {
  pointer-events: none;
}

/* #a11 {
  pointer-events: none;
} */

/* #p20 {
  pointer-events: none;
} */
/* #p15 {
  pointer-events: none;
} */

/* progress */
/* a#\31 5 {
    pointer-events: none;
} */

/* assesment */
/* a#\31 3 {
    pointer-events: none;
} */
/* Mobile Responsive CSS */

.backdrop {
  display: none;
}

.fullSidebar {
  z-index: 2;
  transition: left 0.2s ease-in-out;
  position: fixed;
}

.adminSideBar {
  bottom: 0;
}

.ContentContainer {
  margin: 0 15px;
  margin-top: 80px;
}

.enterPriseContainer {
  padding: 0 20px;
  margin-top: 40px;
}

.OutletContainer {
  margin-left: 220px;
}

@media only screen and (max-width: 600px) {
  .fullSidebar {
    position: fixed;
    left: -240px;
    /* display: none; */
  }

  .ContentContainer {
    margin-top: 90px;
  }

  .backdrop {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #00000080;
  }

  .OutletContainer {
    margin-left: 0;
  }
}