.logoimg {
  top: 10px;
  margin: 20px 0px;
}

.sidebarResponsive {
  padding-top: 62px;
  /* overflow: auto; */
  /* display: flex; */
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.enterpriseLogo {
  margin: 30px 0px 15px 0px;
  height: 50px;
  width: 150px;
  object-fit: contain;
}

@media only screen and (max-width: 768px) {
  .logoimg {
    margin: 30px 0px;
  }
}
