.profilePic {
  display: flex;
  flex-direction: column;
  /* width: fit-content; */
  align-items: center;
}

.profile {
  position: relative;
  width: fit-content;
  width: -moz-fit-content;
}

.icon {
  position: absolute;
  right: 10px;
  bottom: 25px;
}
