.notifications {
  background-color: #fff;
}

.notifications > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 500px;
  padding: 14px 0;
}

@media only screen and (max-width: 768px) {
  .notifications > div {
    width: 80vw;
  }
}
