.consentForm {}

.consentWrapper {
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 8%);
    border-radius: 20px;
    padding: 20px;
    position: relative;
}

.consentHead {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 12px;
    display: flex;
    align-items: center;
    color: #2A2A2A;
    padding: 10px 0;
    background: #fff;

}

.custName {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 15px;
    line-height: 24px;

}

.approved {
    background-color: #00B091;
    color: #ffffff;
    margin-left: 10px;
    font-size: 9px;
    padding: 5px 10px;
    text-align: center;
    border-radius: 20px;
    text-transform: uppercase;
}

.pending {
    background-color: #2e362e;
    color: #fff;
    margin-left: 10px;
    padding: 5px 10px;
    text-align: center;
    font-size: 9px;
    border-radius: 20px;
    text-transform: uppercase;
}

.consentBody {
    margin-top: 15px;
    min-height: 500px;
    height: 500px;
    overflow: auto;
}

.consentBodyContent {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #2A2A2A;
    margin-bottom: 10px;
}

.consentBodyContent h5 {
    text-align: center;
    font-weight: 700;
    font-size: 16px;
}

.subscription {
    border-bottom: 1px solid #ddd;
    padding: 20px 0;
    margin-bottom: 15px;
}

.consentBodyContent p {
    font-size: 16px;
    line-height: 27px;
}

.consentBodyContent input,
.consentBodyContent input:focus,
.consentBodyContent input:active,
.consentBodyContent input:hover,
.consentBodyContent input:focus-visible {
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid #dadada;
    outline: none;
    margin: 0 10px 0 10px;
    width: 300px;
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #2A2A2A;
}

.genrateLink {
    margin-top: 10px;
}

.consentBodyTitle {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #2A2A2A;
    margin: 10px 0;
}

.headingName {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #2A2A2A;
    margin: 10px 0 10px;
    text-align: center;

}

.termAndComp {
    margin-top: 30px;
    margin-bottom: 20px;
}


.termAndComp p {
    margin-left: 20px;
    margin-bottom: 20px;
    line-height: 22px;
}

.termAndComp ul {
    list-style-type: none;
    margin-left: 20px;
    margin-top: 10px;
}

.consentFooter {}