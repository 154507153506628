.dashboard {
  width: 100%;
  margin: 0px auto;
  padding: 0 10px;
}

.NoData {
  height: 228px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bannerImgDesktop {
  text-align: center;
  display: block;
}

.bannerImgMobile {
  display: none;
}

.noData {
  width: 100%;
}

.nodataDiv {}

.btnGroup {}

.deshLink {
  width: 100%;
  text-align: center;
  margin-bottom: 6px;
}

.dashboard>div>div {
  display: flex;
  margin: 10px 0;
  padding: 0 10px;
  width: 100%;
  justify-content: space-between;
  z-index: 0;
}

.assesmentResult {
  width: 282px;
  padding: 5px;
}

.heading {
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.heading>a {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #7a51ae;
  text-decoration: none;
}

.articles {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  padding: 18px 25px 25px;
  margin: 15px 0px;
  background: #fff;
}

.article {
  margin: 30px 0px;
  width: 32%;
  /* margin-top: 10px; */
}

.selfCarebox {
  padding: 18px 25px 25px;
  margin: 15px 0px;
  background: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
}

.selfCare {
  gap: 10px;
}

.mainHeading>span {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
}

.articleContainer {
  flex-direction: row !important;
  flex-wrap: wrap;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .cards {
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }

  .assesmentResult {
    width: 350px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .dashboard {
    padding: 0 10px;
  }

  .article {
    width: 48%;
    max-width: 100%;
  }

  .dashboard>div>div {
    display: flex;
    flex-direction: column;
  }

  .articleContainer {
    flex-direction: row !important;
    flex-wrap: wrap;
  }

  .assesmentResult {
    width: 500px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .dashboard>div>div {
    flex-direction: column-reverse;
  }

  .dashboard {
    width: 100%;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }

  .article,
  .assesmentResult {
    width: 300px;
  }
}

@media (max-width: 768px) {
  .bannerImgDesktop {
    display: none;
  }

  .bannerImgMobile {
    display: block;
    text-align: center;
  }
}