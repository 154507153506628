.Container {
  display: flex;
  justify-content: space-evenly;
  margin: 30px 0px;
}

.dateContainer {
  display: grid;
  grid-template-columns: repeat(7, 50px);
  grid-row-gap: 6px;
  justify-items: center;
}

.rounded {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 6px; */
  cursor: pointer;
  background-color: #8080804a;
  margin: 0 2px;
}

.progressContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.progressContainerWrapper {
  position: relative;
  display: inline-flex;
  justify-content: center;
}

.progressValue {
  font-size: 30px;
  margin-bottom: 10px;
}

.progressTextQuestion {
  text-align: center;
}

.progressTextContainer {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.mainHeading {
  margin-left: 0;
  margin-top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0px;
  padding-right: 15px;
}
.mainHeading > span {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .cards {
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .Container {
    flex-direction: column;
    gap: 20px;
  }

  .progressTextQuestion {
    text-align: center;
    margin-top: 10px;
  }
  .dateContainer {
    justify-content: center;
  }
}

.rounded {
  font-weight: bold;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .Container {
    flex-direction: column;
    gap: 20px;
  }

  .progressTextQuestion {
    text-align: center;
    margin-top: 10px;
  }
  .dateContainer {
    justify-content: center;
  }
}
